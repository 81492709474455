import { objectify } from '../utils/objectify';
import { Community } from './community.models';
import { MollakLandStatusEnum, MollakLandTypeEnum } from './mollak.models';
import { UnitFlags, UnitSubType } from './unit.models';
import { format } from 'date-fns';

export interface UnitFireBase {
	id: string,
	name: string,
	building: any,
	buildingName: string,
	buildingArabicName: string,
	created_at: Date,
	project: string,
	cost_center: string,
	costCenter: string,
	ccName: string,
	type: string,
	subType: string | null,
	plot: string,
	dewa_id: string,
	area: number,
	actual_area: number,
	actualArea: number,
	balcony_area: number,
	balconyArea: number,
	suit_area: number,
	suitArea: number,
	floor: string,
	bedrooms: number,
	bathrooms: number,
	parking_of: string,
	parkings: number,
	rent_rate: string,
	mollakid: string,
	mollakName: string,
	address: string,
	management_start_date: any,
	management_end_date: any,
	is_managed: boolean,
	portfolio: string,
	tenancy_status: string,
	access_management_definitions: object,
	pictures: object,
	documents: object,
	updated_at: Date,
	management_fee: string,
	status: any,
	marketing_title: string,
	marketing_description: string,
	marketing_alt_description: string,
	marketing_agent_signature: any,
	marketing_company_profile: any,
	marketing_property: any,
	marketing_community_desc: any,
	marketing_template: any,
	marketing_contacts: any,
	posted_by: string,
	detailedName: string,
	numberOfParkingSpaces: number,
	dldNumber: string,
	isUpcoming: boolean,
	parkingOf?: string,
	flags:UnitFlags,
	mollakLandType?: MollakLandTypeEnum,
	mollakLandStatus?: MollakLandStatusEnum,
	alternativeCodeName?: string | null
}

export interface UnitForList extends UnitFireBase {
	landlords: string,
	tenants: string,
}

export interface UnitPG {
	id: string,
	name: string,
	building: string,
	createdAt: string,
	community: string,
	costCenter: string,
	type: UnitType,
	subType: UnitSubType | null,
	plot: string,
	dewaId: string,
	area: number,
	actualArea: number,
	balconyArea: number,
	suitArea: number,
	floor: string,
	bedrooms: number,
	bathrooms: number,
	parkingOf: string, // self ref
	mollakId: string,
	mollakName: string,
	address: string,
	managementStartDate: string,
	managementEndDate: string,
	isManaged: boolean,
	portfolio: string,
	accessManagementDefinitions: object,
	updatedAt: string,
	managementFee: string,
	status: UnitStatus,
	marketingTitle: string,
	marketingDescription: string,
	marketingAltDescription: string,
	marketingAgentSignature: string,
	marketingCompanyProfile: string,
	marketingProperty: string,
	marketingCommunityDesc: string,
	marketingTemplate: string,
	postedBy: string, // user foreign key
	detailedName: string,
	snagReportId?: string,
	devPlot?: string,
	dldId?: string,
	numberOfParkingSpaces: number,
	dldNumber: string,
	isVacant: boolean,
	isUpcoming: boolean,
	flags:UnitFlags,
	oldId?: string,
	migratedFrom?: string,
	mollakLandType?: MollakLandTypeEnum,
	mollakLandStatus?: MollakLandStatusEnum,
	alternativeCodeName?: string | null
}
export type UnitStatus = 'vacant' | 'occupied' | 'upcoming' | 'reserved' | 'blocked' | 'leased' | 'terminated' | 'renew';

export type UnitType = 'apm' | 'coa' | 'cod' | 'com' | 'cov' | 'coy' | 'loc' |
	'off' | 'par' | 'res' | 'ret' | 'sig' | 'sto' | 'ter' | 'toh' | 'vil' | 'war';

export interface UnitTypeModel {
	id: string,
	description: string,
	position: number
}

export type Unit = UnitPG;

const defaultUnit: Required<UnitPG> = {
	id: '',
	name: '',
	building: '',
	createdAt: format(new Date(), 'yyyyMMdd'),
	community: '',
	costCenter: '',
	type: 'apm',
	plot: '',
	dewaId: '',
	area: 0,
	actualArea: 0,
	balconyArea: 0,
	suitArea: 0,
	floor: '',
	bedrooms: 0,
	bathrooms: 0,
	parkingOf: '', // self ref
	mollakId: '',
	mollakName: '',
	address: '',
	managementStartDate: '',
	managementEndDate: '',
	isManaged: false,
	portfolio: '',
	accessManagementDefinitions: {},
	updatedAt: '',
	managementFee: '',
	status: 'vacant',
	marketingTitle: '',
	marketingDescription: '',
	marketingAltDescription: '',
	marketingAgentSignature: '',
	marketingCompanyProfile: '',
	marketingProperty: '',
	marketingCommunityDesc: '',
	marketingTemplate: '',
	postedBy: '', // user foreign key
	detailedName: '',
	snagReportId: '',
	devPlot: '',
	dldId: '',
	numberOfParkingSpaces: 1,
	dldNumber: '',
	isVacant: true,
	isUpcoming: false,
	subType: 'apa',
	flags: {
		isUnderLegalCase: false,
		isBlocked: false
	},
	oldId: '',
	migratedFrom: '',
	mollakLandType: MollakLandTypeEnum.Residential,
	mollakLandStatus: MollakLandStatusEnum.Vacant,
	alternativeCodeName: ''
};

const defaultUnitArea = {
	id:'',
	area: 0,
	actualArea: 0,
	balconyArea: 0,
	suitArea: 0,
}



export const getDefaultUnit = () => {
	return objectify( defaultUnit );
};

export interface UpdateUnitsAreaTemplate  {
	id: String | null;
	name: String;
	area?: Number | null;
	actualArea?: Number | null;
	balconyArea?: Number | null ;
	suitArea?: Number | null;
}

export const getDefaultUnitForArea = () =>{
	return objectify( defaultUnitArea );
}



export const transformUnit = ( fromEntity: Partial<UnitFireBase> ) => {
	const createdDateArray = fromEntity.created_at?.toString()?.split( '-' );
	const updatedDateArray = fromEntity.updated_at?.toString()?.split( '-' );
	let createdDate = format(new Date(),'yyyyMMdd')
	let updatedDate = '';
	if ( createdDateArray && createdDateArray.length >= 3 ) {
		const year = createdDateArray[ 0 ];
		const month = createdDateArray[ 1 ];
		const day = createdDateArray[ 2 ];
		createdDate = ( parseInt( year ) * 10000 + parseInt( month ) * 100 + parseInt( day ) ).toString();
	}

	if ( updatedDateArray && updatedDateArray.length >= 3 ) {
		const year = updatedDateArray[ 0 ];
		const month = updatedDateArray[ 1 ];
		const day = updatedDateArray[ 2 ];
		updatedDate = ( parseInt( year ) * 10000 + parseInt( month ) * 100 + parseInt( day ) ).toString();
	}
	return {
		id: fromEntity.id,
		name: fromEntity.name,
		building: fromEntity.building,
		createdAt: createdDate,
		community: fromEntity.project,
		costCenter: fromEntity.cost_center,
		type: fromEntity.type,
		subType: fromEntity.subType,
		plot: fromEntity.plot,
		dewaId: fromEntity.dewa_id,
		area: fromEntity.area,
		actualArea: fromEntity.actual_area,
		balconyArea: fromEntity.balcony_area,
		suitArea: fromEntity.suit_area,
		floor: fromEntity.floor,
		bedrooms: fromEntity.bedrooms,
		bathrooms: fromEntity.bathrooms,
		parkingOf: fromEntity.parking_of, // self ref
		mollakId: fromEntity.mollakid,
		mollakName: fromEntity.mollakName,
		address: fromEntity.address,
		managementStartDate: fromEntity.management_start_date,
		managementEndDate: fromEntity.management_end_date,
		isManaged: fromEntity.is_managed,
		portfolio: fromEntity.portfolio,
		accessManagementDefinitions: fromEntity.access_management_definitions,
		updatedAt: updatedDate,
		managementFee: fromEntity.management_fee,
		status: fromEntity.status,
		marketingTitle: fromEntity.marketing_title,
		marketingDescription: fromEntity.marketing_description,
		marketingAltDescription: fromEntity.marketing_alt_description,
		marketingAgentSignature: fromEntity.marketing_agent_signature,
		marketingCompanyProfile: fromEntity.marketing_company_profile,
		marketingProperty: fromEntity.marketing_property,
		marketingCommunityDesc: fromEntity.marketing_community_desc,
		marketingTemplate: fromEntity.marketing_template,
		postedBy: fromEntity.posted_by, // user foreign key
		detailedName: fromEntity.detailedName,
		numberOfParkingSpaces: fromEntity.numberOfParkingSpaces,
		dldNumber: fromEntity.dldNumber,
		isUpcoming: fromEntity.isUpcoming,
		flags:fromEntity.flags,
		mollakLandStatus: fromEntity.mollakLandStatus,
		mollakLandType: fromEntity.mollakLandType,
		alternativeCodeName: fromEntity?.alternativeCodeName
	} as UnitPG;
};

export const reverseTransformUnit = ( fromEntity: UnitPG ) => {
	const createdDate: Date = new Date();
	const updatedDate: Date = new Date();
	const createDateString = fromEntity.createdAt;
	const updateDateString = fromEntity.updatedAt;

	if ( createDateString ) {
		const year = parseInt( createDateString.substr( 0, 4 ) );
		createdDate.setFullYear( year );
		const month = parseInt( createDateString.substr( 4, 2 ) );
		createdDate.setMonth( month - 1 );
		const day = parseInt( createDateString.substr( 6, 2 ) );
		createdDate.setDate( day );
	}

	if ( updateDateString ) {
		const year = parseInt( updateDateString.substr( 0, 4 ) );
		createdDate.setFullYear( year );
		const month = parseInt( updateDateString.substr( 4, 2 ) );
		createdDate.setMonth( month - 1 );
		const day = parseInt( updateDateString.substr( 6, 2 ) );
		createdDate.setDate( day );
	}

	return {
		id: fromEntity.id,
		name: fromEntity.name,
		building: fromEntity.building,
		created_at: createdDate,
		project: fromEntity.community,
		cost_center: fromEntity.costCenter,
		type: fromEntity.type,
		subType: fromEntity.subType,
		plot: fromEntity.plot,
		dewa_id: fromEntity.dewaId,
		area: fromEntity.area,
		actual_area: fromEntity.actualArea,
		balcony_area: fromEntity.balconyArea,
		suit_area: fromEntity.suitArea,
		floor: fromEntity.floor,
		bedrooms: fromEntity.bedrooms,
		bathrooms: fromEntity.bathrooms,
		parking_of: fromEntity.parkingOf, // self ref
		mollakid: fromEntity.mollakId,
		mollakName: fromEntity.mollakName,
		address: fromEntity.address,
		management_start_date: fromEntity.managementStartDate,
		management_end_date: fromEntity.managementEndDate,
		is_managed: fromEntity.isManaged,
		portfolio: fromEntity.portfolio,
		access_management_definitions: fromEntity.accessManagementDefinitions,
		updated_at: updatedDate,
		management_fee: fromEntity.managementFee,
		status: fromEntity.status,
		marketing_title: fromEntity.marketingTitle,
		marketing_description: fromEntity.marketingDescription,
		marketing_alt_description: fromEntity.marketingAltDescription,
		marketing_agent_signature: fromEntity.marketingAgentSignature,
		marketing_company_profile: fromEntity.marketingCompanyProfile,
		marketing_property: fromEntity.marketingProperty,
		marketing_community_desc: fromEntity.marketingCommunityDesc,
		marketing_template: fromEntity.marketingTemplate,
		posted_by: fromEntity.postedBy, // user foreign key
		detailedName: fromEntity.detailedName,
		numberOfParkingSpaces: fromEntity.numberOfParkingSpaces,
		parkingOf: fromEntity.parkingOf,//for old data
		dldNumber: fromEntity.dldNumber,
		flags:fromEntity.flags,
		mollakLandStatus: fromEntity.mollakLandStatus,
		mollakLandType: fromEntity.mollakLandType,
		alternativeCodeName: fromEntity?.alternativeCodeName
	} as UnitFireBase;
};
export interface MyUnit {
	unitName: string,
	unit: string,
	communityName: string,
	community: string,
	building: string,
	buildingName: string,
	CostId: string,
	CostName: string,
	contractType: string,
	leaseStart: string,
	leaseEnd: string,
	ownedOn: string,
	landlord: string,
	balance?: number,
	closingBalance?: number,
	communityVM?: Community,
	paymentPolicies?: any,
	cashAtCreditCardProcessor?: number,
	refType?: string,
	unitType: string,
	contractNumber: string,
	havePayments: boolean
}
/* to update selected and not selected data in unit */
export interface updateUnitsData {
	ids:string[],
	payload:Partial<UnitPG>
}


export interface LeaseUnitDetails {
	unit: string,
	community: string,
	communityName: string,
	lease: string,
	start: string,
	end: string,
	contractNumber:string,
}
