import { objectify } from '../utils/objectify';
import { Building } from './buildings.model';
import { Community } from './community.models';
import { Contact, ContactTuple } from './contacts.models';
import { Facility } from './facility.model';
import { getDefaultRequestDetails, RequestDetails, RequestDetailsMaintenance } from './requestDetails.models';
import { Unit } from './unit.pg.model';



/**
 * @property id - the request id
 * @property type - type of request
 * @property requestDetails - all request details like rating..
 * @property status - request status
 * @property message - request message
 * @property community - request community id
 * @property unit - request unit id
 * @property contact - contact id for request
 * @property client - client id
 * @property updatedBy - id of contact
 * @property updatedOn - update date
 * @property adminRemark - remark from admin for request
 * @property transformRemark - save reason to showing for request contact
 * @property respondedOn - date of responsed by employee
 * @property responder - contact id how is respons request
 * @property emailId - it's email id, in  replied request
 * @property requestFor - contact id, to know who is grantee Contact for the request
 * @property deletedBy - contact id, to know who is deleted the request
 * @property transformedBy - contact id - to kow who is transform the request
 * @property referenceNumber - to generate sequence to reference number
 * @property facility - to save facility id for this request
 * @property paymentStatus - to payment Status
 * @property payDueDate - pay due date
 * @property needConfirmation - need confirmation boolean kay
 * @property confirmed - check request confirmed
 * @property confirmationEmailSentOn - date of send email confirmation
 * @property source - request source, from where web....
 * @property residents - list of contacts, residents of request
 * @property staffMembers - staff members for this request
 * @property staffMembersIds - list of contact ids
 * @property scJournal - request Journal
 */
export interface Request {
	id: string;
	type: RequestTypeEnumKey;
	requestDetails: RequestDetails;
	status: RequestStatus;
	message: string;
	community: string;
	unit: string;
	contact: string;
	client: string;
	updatedBy: string;
	updatedOn?: string;
	createdOn?: string;
	adminRemark: string;
	transformRemark: string;
	respondedOn: string;
	responder: string;
	emailId: string;
	requestFor?: string;
	deletedBy?: string;
	transformedBy?: string;
	referenceNumber?: string;
	facility?: string;
	paymentStatus: string;
	payDueDate: string;
	needConfirmation: boolean;
	confirmed: boolean;
	confirmationEmailSentOn: string;
	source: RequestSource;
	residents?: string[];
	staffMembers?: any[];
	staffMembersIds?: string[];
	scJournal?: string;
}
export interface RequestFor {
	email: string;
	phone: string;
	name: string;
	middleName: string;
	surname: string;
}

const defaultRequestFor: RequestFor = {
	email: '',
	phone: '',
	name: '',
	middleName: '',
	surname: '',
};

export enum DurationsOfWorkType {
	TwentyFourHours  = '24 hours',
	FortyEightHours = '48 hours',
	SixMonth = '6 months',
}
export const getDefaultedRequestFor = () => {
	return objectify( defaultRequestFor );
};

/**
 * @property communityM - the request community
 * @property untitM - the request unit
 * @property contactM - the contact
 * @property buildingM - to save building
 * @property facilityM - to save request facility
 * @property responderM - to show request responder
 * @property granteeContact - request For contact
 * @property ResidentsVM - to save Residents for this request (contact array)
 * @property StaffMembersVM - to show staff members for this reuest (contact array)
 */
export interface RequestVM extends Request {
	communityM: Community,
	unitM: Unit,
	contactM: Contact,
	buildingM: Building,
	facilityM: Facility,
	responderM: Contact,
	granteeContact: Contact,
	ResidentsVM?: Contact[],
	StaffMembersVM?: Contact[],

}

export const getDefaultRequest = () => {
	return objectify( defaultRequest );
};

const defaultRequest: Request = {
	id: '',
	requestDetails: getDefaultRequestDetails(),
	type: 'moveIn',
	status: 'new',
	message: '',
	community: '',
	unit: '',
	contact: '',
	client: '',
	updatedBy: '',
	adminRemark: '',
	transformRemark: '',
	updatedOn: '',
	createdOn: '',
	respondedOn: '',
	responder: '',
	requestFor: '',
	emailId: '',
	deletedBy: '',
	transformedBy: '',
	referenceNumber: '',
	facility: '',
	paymentStatus: 'not paid',
	payDueDate: '',
	needConfirmation: false,
	confirmed: false,
	confirmationEmailSentOn: '',
	source: 'mobile',
	residents: [],
	staffMembers: [],
	staffMembersIds: [],
	scJournal: '',
};
export type RequestSource = 'web' | 'mobile' | 'migration' | 'manager';
export type RequestStatus = 'new' | 'pending' | 'approved' | 'declined' | 'cancelled' | 'deleted' | 'onHold' | 'delivered';



export enum RequestTypeEnum {
	booking = 'Booking',
	general = 'General',
	landlord = 'Landlord Unit Assignment',
	accessCard = 'Access Card',
	maintenance = 'Maintenance',
	moveIn = 'Move In',
	moveOut = 'Move Out',
	workPermit = 'Work Permit',
	barrierRemote = 'Barrier Remote',
	sccl = 'Service Charge Clearance Letter',
	tenant = 'Tenant Unit Assignment',
	viewing = 'Unit Viewing',
	visitor = 'Visitor',
	proofOfPayment = 'Proof Of Payment',
	lost = 'Lost',
	found= 'found',
	lostFound = 'Lost/Found',
	ownership = 'Owner Ship'
}

export enum DeprecatedRequestTypeEnum {
	booking = 'booking',
	general = 'general',
	landlord = 'landlord',
	accessCard = 'accessCard',
	maintenance = 'maintenance',
	moveIn = 'moveIn',
	moveOut = 'moveOut',
	workPermit = 'workPermit',
	barrierRemote = 'barrierRemote',
	sccl = 'sccl',
	tenant = 'tenant',
	viewing = 'viewing',
	visitor = 'visitor',
	proofOfPayment = 'proofOfPayment',
	lost = 'lost',
	found = 'found',
	lostFound = 'lostFound',
	ownership = 'ownership'
}

export type RequestTypeEnumKey = keyof typeof RequestTypeEnum

export enum RequestStatusEnum {
	new = 'New',
	declined = 'Declined',
	approved = 'Approved',
	cancelled = 'Cancelled',
	deleted = 'Deleted',
	onHold = 'On Hold',
}


export enum RequestsDocuments {
	completionCertificate = 'project-documents-section-c15',
	gasProvisionDrawings = 'project-documents-section-b05',
	tenancyContract = 'tc',
	ejariRegisteration = 'er',
	noc = 'no',
	otherDocs = 'opd',
	dubaiTourismLicense = 'dtl',
	powerOfAttorny = 'pa',
	passport = 'pp',
	titleDeed = 'td',
	nationalId = 'ni',
	visaCopy = 'vi',
	vehicleNo = 'vhc',
	insurance = 'ins',
	workersIds = 'corporate-document-off3',
	tradeLicense = 'tl',
	staturyApproval = 'sa',
	unertakeLetter = 'ul',
	methodStat = 'ms',
	riskAnalys = 'ra',
	drawingsAndPlans = 'dnp',
	operatorsCertificate = 'oc',
	jopd = 'project-documents-section-h09',
	propertyHandover = 'corporate-document-con3',
	releasingkeysNoc = 'releasing-keys-noc',
	developerNoc = 'developer-noc',
	istaNoc = 'ista-noc',
	ownerNoc = 'owner-noc',
	bcc = 'bcc',
	finalGas = 'final-payment-gas',
	finalChiller = 'final-payment-chiller',
	chillerNoc = 'chiller-noc',
	finalDewa = 'final-payment-dewa',
	gasReg = 'registration-gas',
	chilledWaterconnection = 'chilled-water-connection',
	chillerReg = 'registration-chiller',
	dewaReg = 'registration-dewa',
	paymentClear = 'payment-clearance',
	paidSC = 'paid-sc',
	ownerPP = 'owner-pp',
	ownerTD = 'owner-td',
	ul = 'ul',
	brokerLicense = 'broker-license',
	brokerID = 'broker-id',
	viewingApprovalConfirmation = 'viewing-approval-confirmation',
	eid = 'eid',
	southEnergy = 'south-energy',
	leaseAgreement = 'lease-agreement',
	payProof = 'payProof',
	att = 'at',
	PPMServiceReport= 'project-documents-section-a11',
	PPMAttachment= 'project-documents-section-a12',
	WorkCompletionReport= 'project-documents-section-a13',
	titleDeedOrInitialContractOfSaleOrSPA='titleDeedOrInitialContractOfSaleOrSPA',
	gasSupplierNoc = 'noc-from-gas-supplier'
}

export enum RequestsDocumentsTypes {
	eid = 'Emirates ID',
	tc = 'Tenancy Contract',
	er = 'Ejari Registeration',
	no = 'NOC',
	opd = 'Other Docs',
	dtl = 'Tourism License',
	pa = 'Power Of Attorny',
	pp = 'Passport',
	td = 'Title Deed',
	ni = 'Emirates ID',
	vi = 'Visa Copy',
	vhc = 'Vehicle Plate Number',
	ins = 'Insurance',
	tl = 'Trade License',
	sa = 'Statury Approval',
	ul = 'Undertaking Letter',
	ms = 'Method Statement',
	ra = 'Risk Assessment of works',
	dnp = 'Drawings and Plans',
	oc = `Operator's Certificate`,
	'project-documents-section-h09' = 'JOPD',
	'corporate-document-con3' = 'Property Handover',
	'releasing-keys-noc' = 'NOC of Releasing Keys',
	'developer-noc' = 'NOC from Developer',
	'ista-noc' = 'NOC from ISTA chilled water charges clearance letter',
	'owner-noc' = 'NOC Move Out from Owner',
	'bcc' = 'Copy of BCC',
	'final-payment-gas' = 'Copy of Final Gas Payment Receipt',
	'final-payment-chiller' = 'Copy of Final Chiller Payment Receipt',
	'final-payment-dewa' = 'Copy of Final Dewa Payment Receipt',
	'registration-gas' = 'Copy of Gas registration',
	'chilled-water-connection' = 'Copy of Chilled water connection',
	'registration-chiller' = 'Copy of Chiller registration',
	'registration-dewa' = 'Copy of Dewa registration',
	'payment-clearance' = 'Copy of Payment Clearance Certificate from Unit Owner',
	'paid-sc' = 'Paid Service Charge',
	'owner-pp' = 'Owner Passport copy',
	'owner-td' = 'Owner Title Deed Copy',
	'broker-license' = 'Broker License Copy',
	'broker-id' = 'Broker’s Emirates ID',
	'viewing-approval-confirmation' = 'Confirmation on the approval of viewing the unit from the Owner’s registered email address',
	chillerNoc = 'Chiller NOC',
	'south-energy' = 'South Energy Receipt',
	'project-documents-section-c15' = 'Completion Certificates',
	'as' = 'Authorized Signatory',
	'co' = 'Contract',
	'payProof' = 'Proof Of Payment',
	'att' = 'Attachment',
	'titleDeedOrInitialContractOfSaleOrSPA'='Title Deed Or Initial Contract Of Sale Or SPA',
	'noc-from-gas-supplier' = 'NOC from Gas Supplier'
}

export enum RequestStatusesEnum {
	new = 'new',
	declined = 'declined',
	approved = 'approved',
	cancelled = 'cancelled',
	deleted = 'deleted',
	onHold = 'onHold',
}


export const getDocumentsByRequest = ( type:string ) => {
	switch ( type ) {
		case 'moveIn':
			return [
				RequestsDocuments.jopd,
				RequestsDocuments.otherDocs,
				RequestsDocuments.dubaiTourismLicense,
				RequestsDocuments.chillerReg,
				RequestsDocuments.dewaReg,
				RequestsDocuments.finalChiller,
				RequestsDocuments.titleDeed,
				RequestsDocuments.completionCertificate,
				RequestsDocuments.powerOfAttorny,
				RequestsDocuments.ownerNoc,
				RequestsDocuments.tenancyContract,
				RequestsDocuments.ejariRegisteration,
				RequestsDocuments.leaseAgreement,
			];

		case 'moveOut':
			return [
				RequestsDocuments.jopd,
				RequestsDocuments.chillerNoc,
				RequestsDocuments.otherDocs,
				RequestsDocuments.completionCertificate,
				RequestsDocuments.finalChiller,
				RequestsDocuments.finalDewa,
				RequestsDocuments.finalGas,
				RequestsDocuments.titleDeed,
				RequestsDocuments.completionCertificate,
				RequestsDocuments.powerOfAttorny,
				RequestsDocuments.ownerNoc,
				RequestsDocuments.tenancyContract,
				RequestsDocuments.ejariRegisteration ];

		case 'landlord':
			return [ RequestsDocuments.titleDeed ];

		case 'tenant':
			return [
				RequestsDocuments.tenancyContract,
				RequestsDocuments.ejariRegisteration ];

		case 'barrierRemote':
			return [
				RequestsDocuments.otherDocs,
				RequestsDocuments.titleDeed,
				RequestsDocuments.tenancyContract,
				RequestsDocuments.noc ];

		case 'accessCard':
			return [
				RequestsDocuments.otherDocs,
				RequestsDocuments.titleDeed,
				RequestsDocuments.noc,
				RequestsDocuments.ownerPP,
				RequestsDocuments.ownerTD,
				RequestsDocuments.tenancyContract,
				RequestsDocuments.ejariRegisteration ];

		case 'booking':
			return [];

		case 'maintenance':
			return [ 'at', 'after-fixed' ];

		case 'workPermit':
			return [
				RequestsDocuments.jopd,
				RequestsDocuments.developerNoc,
				RequestsDocuments.ownerNoc,
				RequestsDocuments.vehicleNo,
				RequestsDocuments.tradeLicense,
				RequestsDocuments.otherDocs,
				RequestsDocuments.workersIds,
				RequestsDocuments.ul,
				RequestsDocuments.insurance,
				RequestsDocuments.riskAnalys,
				RequestsDocuments.methodStat,
				RequestsDocuments.operatorsCertificate,
				RequestsDocuments.drawingsAndPlans,
				RequestsDocuments.unertakeLetter,
				RequestsDocuments.staturyApproval,
			];
		case 'proofOfPayment':
			return [
				RequestsDocuments.payProof,
			];
		default:
			return [ RequestsDocuments.otherDocs ];
	}
};

export enum RequestUrl {
	'viewing' = 'my-requests/viewing',
	'moveIn' = 'my-requests/move-in',
	'moveOut' = 'my-requests/move-out',
	'workPermit' = 'my-requests/workpermit-request',
	'accessCard' = 'my-requests/accesscard',
	'barrierRemote' = 'my-requests/barrier-remote',
	'maintenance' = 'my-requests/maintenance',
	'landlord' = 'my-requests/landlord',
	'booking' = 'my-requests/booking-request',
	'tenant' = 'my-requests/tenant',
	'general' = 'my-requests/general-request',
	'proofOfPayment' = 'my-requests/proofOfPayment',
	'lostItem' = 'my-requests/lostItem',
}

export type RequestUrlKey = keyof typeof RequestUrl;

export const AllRequestsArray = [
	{ label: 'Move In', url: '/my-requests/move-in', type: 'moveIn', imgSrc: 'moveIn_white' },
	{ label: 'Move Out', url: '/my-requests/move-out', type: 'moveOut', imgSrc: 'moveOut_white' },
	{ label: 'Work Permit', url: '/my-requests/workpermit-request', type: 'workPermit', imgSrc: 'workPermit_white' },
	{ label: 'Access Card', url: '/my-requests/accesscard', type: 'accessCard', imgSrc: 'accessCard_white' },
	{ label: 'Barrier Remote', url: '/my-requests/barrier-remote', type: 'barrierRemote', imgSrc: 'barrierRemote_white' },
	{ label: 'Booking', url: '/my-requests/booking-request', type: 'booking', imgSrc: 'booking_white' },
	{ label: 'General', url: '/my-requests/general-request', type: 'general', imgSrc: 'general_white' },
	{ label: 'Maintenance', url: '/my-requests/maintenance', type: 'maintenance', imgSrc: 'maintenance_white' },
	{ label: 'Unit Viewing', url: '/my-requests/viewing', type: 'viewing', imgSrc: 'viewing_white' },
	{ label: 'Proof Of Payment', url: '/my-requests/proofOfPayment', type: 'proofOfPayment', imgSrc: 'proof_Of_Payment' },
	{ label: 'Lost Found', url: '/my-requests/lostFound', type: 'lostFound', imgSrc: 'lostFound_white' },
	{ label: 'Visitor', url: '/my-requests/visitor-request', type: 'visitor' },
	{ url: '/my-requests/landlord-request', type: 'landlord' },
	{ url: '/my-requests/tenant-request', type: 'tenant' },

];

export const getPersonalDocuments = () => {
	return [ 'pp', 'vi', 'ni', 'eid' ];
};

export const RequestDateFields = [ 'updatedOn', 'createdOn', 'checkOutDate', 'checkInDate', 'viewingDateTime', 'moveInDate', 'moveOutDate', 'leaseStartDate', 'leaseEndDate', 'from', 'to', 'titleDeedDate', 'tenancyDate', 'date' ];

export interface GetRequestsQueryParams {
	/** a date to filter with it */
	filterTo: string,
	/** request status to filter with it */
	status: string,
	/** request type */
	type: RequestTypeEnumKey,
	/** request unit id */
	unit: string
	/** contact id */
	contact: string
}

export interface PostRequestBody extends Request {
	/** grantee contact from request   */
	granteeContact?: Contact & ContactTuple,

	/** it's a boolean to handle contact phone numbers, contact can be request contact or grantee contact   */
	contactUpdate?: boolean

	/** list of residents and staffMembers in this request */
	newContacts?: Array<Contact & ContactTuple>
}

export interface PostRequestReturn {
	id: string
	refNumber: string
}

export interface PutMergedRequestUpdateBody {
	/** request id  */
	id: string

	/** request details object*/
	requestDetails: RequestDetailsMaintenance & RequestDetails
}

export interface GetVisitorsReturnType extends Request {

	/** contact name of this request */
	contactName: string

	/** unit full name, it's like this -> 'communityName/buildingName/unitName' */
	unitDetails: string
}

export interface PostExportCSVRequests {
	/** request type */
	type?: RequestTypeEnumKey
	/** list of communities ids to filter on requests by them  */
	communities?: string[]
	/** date, to filter on requests  */
	to?: string
	/** date, to filter on requests  */
	from?: string
	/** status of this request, ex: approved, deleted etc... */
	status?: string
}

export interface PostRequestsMergeBody {
	/** main request, this request will save the second request */
	resultRequestId: string
	/** second request, this request will merged to the main request */
	toMergeRequestId: string
	/** request details snag */
	requestDetails: RequestDetails & RequestDetailsMaintenance
}

export interface PostRequestShortTermDocs {
	/** request Id */
	request: string

	/** unit  Id */
	unit: string
}

export interface PostSearchRequestReturnType extends Request {
	/** community name of this request */
	communityName: string

	/** unit name of this request  */
	unitName: string

	/** contact name of this request */
	contactName: string
}

export interface PostSearchRequestBody {
	/** list of communities ids*/
	communities?: string[]

	/** list of requests types*/
	types?: string[]

	/** list of units ids*/
	units?: string[]

	/** request reference Number*/
	referenceNumber?: string
}

export enum RequestSubType {
	landlord = 'landlord',
	tenant = 'tenant',
	shortTerm = 'shortTerm',
	minor = 'Minor',
	major = 'Major',
	preApproval = 'Pre-Approval',
  }

export interface RequestType {
	header: string;
	requestSubType: RequestSubType;
	requestType: RequestTypeEnumKey;
	selectedTypes: SelectedType[];
}
  
export interface SelectedType {
	id?: string;
	required: boolean;
	type: string;
	label: string;
	refType: string;
	requestSubType: RequestSubType;
}

export const settingTypes: SettingType[] = [
	{
		requestType: 'moveIn',
		requestSubType: RequestSubType.tenant,
		header: 'Move in for tenant',
	},
	{
		requestType: 'moveIn',
		requestSubType: RequestSubType.landlord,
		header: 'Move in for landlord',
	},
	{
		requestType: 'moveIn',
		requestSubType: RequestSubType.shortTerm,
		header: 'Move in short terms',
	},
	{
		requestType: 'moveOut',
		requestSubType: RequestSubType.tenant,
		header: 'Move out for tenant',
	},
	{
		requestType: 'moveOut',
		requestSubType: RequestSubType.landlord,
		header: 'Move out for landlord',
	},
	{
	  requestType:'lostFound',
	  header: 'Lost/Found',
	},
	{
		requestType: 'accessCard',
		requestSubType: RequestSubType.landlord,
		header: "Access card for landlord",
	},
	{
		requestType: 'accessCard',
		requestSubType: RequestSubType.tenant,
		header: "Access card for tenant",
	},
	{
		requestType: 'barrierRemote',
		requestSubType: RequestSubType.landlord,
		header: 'Barrier remote for landlord',
	},
	{
		requestType: 'barrierRemote',
		requestSubType: RequestSubType.tenant,
		header: 'Barrier remote for tenant',
	},
	{
		requestType: 'booking',
		header: 'Booking',
	},
	{
		requestType: 'general',
		header: 'General',
	},
	{
		requestType: 'landlord',
		header: 'Landlord unit assignment',
	},
	{
		requestType: 'maintenance',
		header: 'Maintenance',
	},
	{
		requestType: 'proofOfPayment',
		header: 'Proof of payment',
	},
	{
		requestType: 'tenant',
		header: 'Tenant unit assignment',
	},
	{
		requestType: 'viewing',
		header: 'Unit viewing',
	},
	{
		requestType: 'visitor',
		header: 'Visitor',
	},
	{
		requestType: 'workPermit',
		requestSubType: RequestSubType.major,
		header: 'Major work permit',
	},
	{
		requestType: 'workPermit',
		requestSubType: RequestSubType.minor,
		header: 'Minor work permit',
	},

]

  type SettingType ={
	requestType: RequestTypeEnumKey,
	requestSubType?: RequestSubType,
	header: string,
  }
