<!-- TODO ALI TO REVIEW -->
<!-- <div class="card"> -->
<!-- <div class="card-header pb-0"> -->
<div #body *ngIf="!pageTitle">
	<h3 *ngIf="showExpiry && expiryDays === 'expired'">Showing expired documents</h3>
	<h3 *ngIf="showExpiry  && expiryDays !== 'expired'">Showing expired & documents expiring within {{expiryDays}} days
	</h3>
	<div class="">
		<label *ngIf="!!header" class="titleText text-primary font-weight-bold">{{header}}</label>
		<div style="display: flex; justify-content: space-between; gap: 10px; flex-wrap: wrap; float:right;">
			<div style="display: flex; gap: 10px; flex-wrap: wrap;">
				<button type="button" (click)="uploadDocument()" class="btn buttonRadius float-right bg-light py-1 px-2"
					*ngIf="!disableUpload" [disabled]="isReadOnly && !enableUploadEvenReadOnly">
					<i class="fa fa-upload fa-fw text-primary"></i>
					<ng-container *ngIf="!onlyIcons"> Upload Documents</ng-container>
				</button>
				<div style="height: fit-content;" *ngIf="showExpiry">
					<div class="input-group input-group-sm">
						<div class="input-group-prepend" *ngIf="limitTo.length > 1">
							<div class="input-group-text">
								Select Within Days:
							</div>
							<select class="custom-select custom-select-sm" [(ngModel)]="expiryDays"
								(ngModelChange)="onFilterChange()">
								<option value="expired">Expired</option>
								<option value="30">30</option>
								<option value="60">60</option>
								<option value="90">90</option>
							</select>
						</div>
					</div>
				</div>
				<div style="height: fit-content;" *ngIf="!onlyIcons">
					<div class="input-group input-group-sm">
						<div class="input-group-prepend pl-0">
							<div class="input-group-text">
								Show Expiry:
							</div>
						</div>
						<div class="input-group-append">
							<div class="input-group-text">
								<input type="checkbox" class="m-1" name="showExpiry" [(ngModel)]="showExpiry"
									(ngModelChange)="onFilterChange()">
							</div>
						</div>
					</div>
				</div>
				<div style="height: fit-content;" *ngIf="!onlyIcons">
					<div class="input-group input-group-sm">
						<div class="input-group-prepend">
							<div class="input-group-text">
								Filter From
							</div>
						</div>
						<input type="text" class="form-control text-monospace px-1" [(ngModel)]="filterFrom" [bsConfig]="bsConfig"
							bsDatepicker (ngModelChange)="onFilterChange()">
						<br>
					</div>
				</div>
				<div style="height: fit-content;" *ngIf="!onlyIcons">
					<div class="input-group input-group-sm">
						<div class="input-group-prepend">
							<div class="input-group-text">
								Filter To
							</div>
						</div>
						<input type="text" class="form-control text-monospace px-1" [(ngModel)]="filterTo" [bsConfig]="bsConfig"
							bsDatepicker (ngModelChange)="onFilterChange()">
						<br>
					</div>
				</div>
			</div>
			<div style="height: fit-content;">
				<div class="input-group input-group-sm">
					<div>
						<button type="button" (click)="saveDocuments()" [disabled]="isSaving || isReadOnly"
							class="btn buttonRadius bg-light ml-3 py-1 pl-2" *ngIf="extended">
							<i class="fa fa-save fa-fw text-primary"></i>
							<ng-container *ngIf="!onlyIcons"> Save Documents</ng-container>
						</button>
					</div>
					<div>
						<button type="button" (click)="exportDocumentList()" [disabled]="isSaving || isReadOnly"
							class="btn buttonRadius bg-light ml-3 py-1 pl-2" *ngIf="extended">
							<i class="fa fa-save fa-fw text-primary"></i>
							<ng-container *ngIf="!onlyIcons"> Export List</ng-container>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="card-body" style="padding: 0px;">
			<ng-container *ngIf="isLoading;else filesListRef">
				<div class="text-center m-5">
					<p>Loading documents, please wait...</p>
					<div class="spinner-border" role="status">
						<span class="sr-only">Loading...</span>
					</div>
				</div>
			</ng-container>


			<ng-template #filesListRef>

			<div *ngIf="!isLoading">
				<ng-container *ngIf="visaPassportDocs; else normalList">
					<div class="p-pt-3" *ngIf="stillNeedVisaPassportDocs">
						<div class="p-text-primary">Required Documents</div>
					</div>
					<div class="p-error p-pl-2" style="white-space:pre-wrap;">{{checkVisaPassportDocsUploaded()}}
					</div>
				</ng-container>
				<ng-template #normalList>
					<div class="p-pt-3" *ngIf="mandatoryDocumentList.length >0">
						<div class="p-text-primary">Required Documents</div>
						<div class="p-error p-pl-2" *ngFor="let mandatoryDocument of mandatoryDocumentList">
							<div [ngClass]="{'p-hidden-text': checkDocumentTypeIsUploaded(mandatoryDocument.type)}">
								{{mandatoryDocument.label}}
							</div>
						</div>
					</div>
				</ng-template>
			</div>
				<ng-container *ngIf="(limitTo.length == 1) && (isUnique); else notSingleLHMI">
					<div *ngFor="let document of filteredDocuments; let i = index; " class="row">
						<div class="col-11 float-left">
							<img (click)="viewDoc(document)" *ngIf="isImage[i]" (error)="errorLoadingImage(i)" [src]="document.url"
								style="max-width: 100%; cursor: pointer">
							<a class="doc-name" *ngIf="!isImage[i]" (click)="viewDoc(document)"
								style="cursor: pointer">{{document.name}}</a>
						</div>
						<button type="button" (click)="deleteDocumentDialog(document.id,document.type)" style="max-height:30px"
							class="btn btn-sm btn-outline-primary float-right" [disabled]="isReadOnly || !canDeleteDocs">
							<i class="fa fa-trash"></i>
							<ng-container *ngIf="!onlyIcons"> Remove</ng-container>
						</button>
					</div>
				</ng-container>
				<ng-template #notSingleLHMI>
					<div class="table-responsive" *ngIf="filteredDocuments && filteredDocuments.length>0;else noDoc">
						<p-table [value]="filteredDocuments" [scrollable]="true" [scrollHeight]="!extended? 'auto' :'475px'"
							[virtualScroll]="extended">
							<ng-template pTemplate="header">
								<tr>
									<th pSortableColumn="name" style="min-width: 150px !important; width: 150px !important">
										<div>
											File Name 
											<p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
											<p-sortIcon field="name"></p-sortIcon>
										</div>
									</th>
									<th style="min-width: 100px !important">
										<div style="display: flex ;gap:10px; align-items: center;">
											Type
											<div *ngIf="documentTypes| async as dts">
												<p-columnFilter field="type" matchMode="in" display="menu" [showMatchModes]="false"
													[showOperator]="false" [showAddButton]="false">
													<ng-template pTemplate="header">
														<div class="px-3 pt-3 pb-0">
															<span class="font-bold">Type filter</span>
														</div>
													</ng-template>
													<ng-template pTemplate="filter" let-value let-filter="filterCallback">
														<p-multiSelect [options]="limitTo" [ngModel]="value" placeholder="Any" [filter]="false"
															(onChange)="filter($event.value)">
															<ng-template let-option pTemplate="item">
																<div>
																	<span class="ml-1">{{ dts[option]?.description }}</span>
																</div>
															</ng-template>
														</p-multiSelect>
													</ng-template>
												</p-columnFilter>
											</div>
										</div>
									</th>
									<th pSortableColumn="uploadDate" style="min-width: 100px !important; width: 100px !important">
										<div style="display: flex ;gap:10px; align-items: center;">
											Date Uploaded
											<p-sortIcon field="uploadDate"></p-sortIcon>
										</div>
									</th>
									<ng-container *ngIf="extended">
										<th style="min-width: 150px !important; width: 150px !important" *ngIf="!vendorDocs">
											<div style="display: flex; align-items: center;">
												Uploaded By
												<p-columnFilter type="text" field="uploadedByName" display="menu"></p-columnFilter>
											</div>
										</th>
										<th class="font-weight-semibold border-bottom"
											style="min-width: 200px !important; width: 200px !important"
											*ngIf="limitTo.includes('project-documents-section-a01') || limitTo.includes('project-documents-section-a11') || !!vendorDocs">
											<div>
												Service Provider
												<p-columnFilter field="contactName" display="menu" matchMode="in" [showMatchModes]="false"
													[showOperator]="false" [showAddButton]="false" [showApplyButton]="false"
													[showClearButton]="false">
													<ng-template pTemplate="header">
														<div class="p-px-3 p-pt-3 p-pb-0">
															<span class="p-text-bold">Filter on Service Provider</span>
														</div>
													</ng-template>
													<ng-template pTemplate="filter" let-value let-filter="filterCallback">
														<p-multiSelect [options]="contactsArray" optionLabel="displayName" optionValue="displayName"
															[ngModel]="value" placeholder="Any" (onChange)="filter($event.value)"></p-multiSelect>
													</ng-template>
												</p-columnFilter>
											</div>
										</th>
										<th style="min-width: 150px !important; width: 150px !important"
											*ngIf="limitTo.includes('project-documents-section-a11') && !vendorDocs">Frequency of Service</th>
										<th style="min-width: 400px">Dates</th>
										<th style="min-width: 120px; width: 120px !important" *ngIf="!vendorDocs">Notification Range</th>
										<th style="min-width: 90px; width: 90px;">Status</th>
									</ng-container>
									<ng-container *ngIf="leaseId" >
										<th style="min-width: 100px !important; width: 100px !important">
											<div style="display: flex ;gap:10px; align-items: center; margin: auto;">
												Sent To Landlord 
											</div>
										</th>
										<th  style="min-width: 100px !important; width: 100px !important">
											<div style="display: flex ;gap:10px; align-items: center;margin: auto;">
												Landlord Approval
											</div>
										</th>
										<th style="min-width: 100px !important; width: 100px !important">
											<div style="display: flex ;gap:10px; align-items: center;margin: auto;">
												Sent To Tenant 
											</div>
										</th>
										<th style="min-width: 100px !important; width: 100px !important">
											<div style="display: flex ;gap:10px; align-items: center;margin: auto;">
												Tenant Approval
											</div>
										</th>
									</ng-container>
									<th style="min-width: 60px; width: 60px;"><i class="fa fa-ellipsis-h mr-3"></i></th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-document let-i>
								<tr [style.background-color]="(document.status === 'active') ? '': '#f7bccb' ">
									<td style="min-width: 150px !important; width: 150px !important">
										<div class="input-group input-group-sm">
											<div class="input-group-prepend w-100">
												<div class="input-group-tebuttonxt w-100">
													<a class="doc-name" (click)="viewDoc(document)" style="cursor: pointer">{{document.name}}</a>
												</div>
											</div>
											<p style="width: 100%;"></p>
										</div>
										<div class="col-12 p-p-1 w-100" *ngIf="document.type===documentTypeEnum.PurchaseInvoice && document.refType==='journalPU' && (!!isPropertyAccountanat || !! isPropertyManager) && 
										(!document.approved || document.approvalStatus !=='approved')" >
												<p-badge [value]="'Pending CM Approval'" severity="warning"
												*ngIf="document.approvalStatus !=='approved'">
												</p-badge>
												<p-badge [value]="'Pending Accountant Approval'" severity="warning"
												*ngIf="!document.approved && document.approvalStatus ==='approved'">
												</p-badge>
												<p-badge [value]="'Rejected'" severity="danger"
												*ngIf="!document.approved && document?.approvalStatus==='rejected' ">
												</p-badge>
										</div>
									</td>
									<td style="min-width: 100px !important">
										<ng-container *ngIf="documentTypes| async as dts">
											<ng-container *ngIf="extended else notExtended">
												<select class="custom-select custom-select-sm" [(ngModel)]="document.type"
													[disabled]="isReadOnly">
													<option [ngValue]="documentType" *ngFor="let documentType of limitTo">
														{{ dts[documentType]?.description }}
													</option>
												</select>
											</ng-container>
											<ng-template #notExtended>
												<div class="input-group input-group-sm" style="height: calc(1.5em + 0.8rem );">
													<div class="input-group-prepend w-100">
														<div class="input-group-text w-100">
															{{dts[document.type]?.description}}
														</div>
													</div>
												</div>
											</ng-template>
										</ng-container>
									</td>
									<td style="min-width: 100px !important; width: 100px !important">
										<div class="input-group input-group-sm" style="height: calc(1.5em + 0.8rem );"
											*ngIf="document.uploadDate !== undefined">
											<div class="input-group-prepend w-100">
												<div class="input-group-text w-100">
													{{ document.uploadDate | mask: '0000-00-00' }}
												</div>
											</div>
										</div>
									</td>
									<ng-container *ngIf="extended && ss.documentTypes | async as dts">
										<td style="min-width: 150px !important; width: 150px !important" *ngIf="!vendorDocs">
											<div class="input-group input-group-sm" style="height: calc(1.5em + 0.8rem );"
												*ngIf="document.uploadedByName !== undefined">
												<div class="input-group-prepend w-100">
													<div class="input-group-text w-100 text-overflow" [pTooltip]="document.uploadedByName"
														tooltipPosition="top">
														{{ document.uploadedByName }}
													</div>
												</div>
											</div>
										</td>
										<td class="px-2 pt-3 pb-2 border-bottom" *ngIf="limitTo.includes('project-documents-section-a01') || !!vendorDocs"
											style="min-width: 200px !important; width: 200px !important">
											<p-dropdown [virtualScroll]="true" [virtualScrollItemSize]="30" [disabled]="isReadOnly"
												*ngIf="document.type === 'project-documents-section-a01' || document.type === 'project-documents-section-a11' || !!vendorDocs"
												[filter]="true" filterBy="displayName" required name="contact{{i}}" appendTo="body"
												[options]="contactsArray" [(ngModel)]="document.service" autoWidth="false"
												[style]="{'width':'100%'}" optionLabel="displayName" optionValue="id"
												placeholder="Please Choose Service Provider " (onChange)="onContactSelect($event, document)">
											</p-dropdown>
										</td>
										<td class="px-2 pt-3 pb-2 border-bottom w-50"
											*ngIf="limitTo.includes('project-documents-section-a11') && !vendorDocs"
											style="min-width: 150px !important; width: 150px !important">
											<div class="input-group input-group-sm">
												<input [disabled]="isReadOnly" type="text" class="form-control form-control-sm"
													[(ngModel)]="document.serviceFrequency"
													*ngIf="document.type === 'project-documents-section-a11'">
											</div>
										</td>
										<td style="min-width: 400px" *ngIf="!vendorDocs">
											<div class="input-group input-group-sm flex-dates">
												<div class="date-input" *ngIf="dts[document.type].hasStartDate">
													<div class="input-group-prepend">
														<div class="input-group-text">Start</div>
													</div>
													<p-calendar [(ngModel)]="document.startDate" dateFormat="yy-mm-dd" dataType="string" [panelStyle]="{'width': '24rem'}"
														appendTo="body">
													</p-calendar>
													<!--
													<input [disabled]="isReadOnly" type="text" class="form-control text-monospace px-1"
														[(ngModel)]="document.startDate" [bsConfig]="bsConfig" bsDatepicker />
														-->
														<br />
												
												</div>
												<div class="date-input" *ngIf="dts[document.type].hasEndDate">
													<div class="input-group-prepend">
														<div class="input-group-text">End</div>
													</div>
													<p-calendar [(ngModel)]="document.endDate" dateFormat="yy-mm-dd" dataType="string" [panelStyle]="{'width': '24rem'}"
														appendTo="body">
													</p-calendar>
													<!--
													<input [disabled]="isReadOnly" type="text" class="form-control text-monospace px-1"
														[(ngModel)]="document.endDate" [bsConfig]="bsConfig" bsDatepicker />
															-->
													<br />
												</div>
												<div class="date-input" *ngIf="dts[document.type].hasIssueDate">
													<div class="input-group-prepend">
														<div class="input-group-text">Issue</div>
													</div>
													<p-calendar [(ngModel)]="document.issueDate" dateFormat="yy-mm-dd" dataType="string" [panelStyle]="{'width': '24rem'}"
														appendTo="body">
													</p-calendar>
													<!--
													<input [disabled]="isReadOnly" type="text" class="form-control text-monospace px-1"
														[(ngModel)]="document.issueDate" [bsConfig]="bsConfig" bsDatepicker />
														-->
													<br />
												</div>
												<div class="date-input" *ngIf="dts[document.type].hasExpiryDate">
													<div class="input-group-prepend">
														<div class="input-group-text">Expiry</div>
													</div>
													<p-calendar [(ngModel)]="document.expiryDate" dateFormat="yy-mm-dd" dataType="string" [panelStyle]="{'width': '24rem'}"
														appendTo="body">
													</p-calendar>
													<!--
													<input [disabled]="isReadOnly" type="text" class="form-control text-monospace px-1"
														[(ngModel)]="document.expiryDate" [bsConfig]="bsConfig" bsDatepicker />
														-->
													<br />
												</div>
												<div class="date-input" *ngIf="dts[document.type].hasDueDate">
													<div class="input-group-prepend">
														<div class="input-group-text">Due</div>
													</div>
													<input [disabled]="isReadOnly" type="text" class="form-control text-monospace px-1"
														[(ngModel)]="document.dueDate" [bsConfig]="bsConfig" bsDatepicker />
													<br />
												</div>
												<div class="date-input" *ngIf="dts[document.type].hasRenewalDate">
													<div class="input-group-prepend">
														<div class="input-group-text">Renewal</div>
													</div>
													<input [disabled]="isReadOnly" type="text" class="form-control text-monospace px-1"
														[(ngModel)]="document.renewalDate" [bsConfig]="bsConfig" bsDatepicker />
												</div>
											</div>
										</td>
										<td style="min-width: 120px; width: 120px !important">
											<div class="input-group input-group-sm" *ngIf="document.notificationRange !== undefined">
												<input [disabled]="isReadOnly" type="number" class="form-control text-right"
													[(ngModel)]="document.notificationRange">
												<div class="input-group-append">
													<div class="input-group-text">
														Days &nbsp;
													</div>
												</div>
											</div>
										</td>


										<td style="min-width: 90px; width: 90px;">
											<div class="input-group input-group-sm" style="height: calc(1.5em + 0.8rem );">
												<div class="input-group-prepend w-100">
													<div class="input-group-text w-100">
														{{ document.status }}
													</div>
												</div>
											</div>
										</td>
									</ng-container>
									<ng-container *ngIf="leaseId">
										<td style="min-width: 100px !important; width: 100px !important ;">
											<div class="input-group input-group-sm" style="display:contents;"
												*ngIf="document.uploadDate !== undefined">
												<div>
													<i  *ngIf="!sendToLandlordLoadingMap[document.id] && document.id" (click)="sendToTenantLandlord(document,'landlord',leaseId)" type="button" style="font-size: x-large;" class="fa fa-paper-plane" aria-hidden="true"></i>
													<i  *ngIf="sendToLandlordLoadingMap[document.id] && document.id" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
												</div>
												<div class="input-group-prepend w-100" style="display:block">
													<div *ngFor="let dateSend of getFilteredSentHistory(document,'landlord')" class="input-group-text w-100" style="justify-content: center;">
														{{ dateSend.date | mask: '0000-00-00' }}
													</div>
												</div>
											</div>
										</td>
										<td style="min-width: 60px; width: 60px;">
											<div *ngIf="!approvableLeaseDocument.includes(document?.type); else landlordApproval" style="font-size: x-small;">
												This document type don't have status
											</div>
											<ng-template #landlordApproval>
												<div style="margin: auto;" >
													<button type="button" pButton [disabled]="isSaving || document?.landlordApprovalStatus =='approved'"
													 *ngIf="(['approved','pending'].includes(document?.landlordApprovalStatus)) "
														class="p-button-raised p-button-rounded p-button-success p-float-right p-ml-2" icon="pi pi-check"
														pTooltip="Approve" tooltipPosition="top"
														(click)="approveRejectLeaseDocument('approve',document?.id,'landlord')"></button> 
													<button type="button" pButton class="p-button-raised p-button-rounded p-button-danger p-ml-2"
														icon="pi pi-times" pTooltip="Reject" tooltipPosition="top"
														(click)="approveRejectLeaseDocument('reject',document?.id,'landlord')" [disabled]="isSaving||document?.landlordApprovalStatus =='rejected'"
														*ngIf="(['rejected','pending'].includes(document?.landlordApprovalStatus))"></button>
												</div>
											</ng-template>
										</td>
										<td style="min-width: 100px !important; width: 100px !important ;">
											<div class="input-group input-group-sm" style="display:contents;"
												*ngIf="document.uploadDate !== undefined">
												<div>
													<i *ngIf="!sendToTenantLoadingMap[document.id] && document.id" (click)="sendToTenantLandlord(document,'tenant',leaseId)" type="button" style="font-size: x-large;" class="fa fa-paper-plane" aria-hidden="true"></i>
													<i *ngIf="sendToTenantLoadingMap[document.id] && document.id" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
												</div>
												<div class="input-group-prepend w-100" style="display:block">
													<div *ngFor="let dateSend of getFilteredSentHistory(document,'tenant')" class="input-group-text w-100" style="justify-content: center;">
														{{ dateSend.date | mask: '0000-00-00' }}
													</div>
												</div>
											</div>
										</td>
										<td style="min-width: 60px; width: 60px; ">
											<div *ngIf="!approvableLeaseDocument.includes(document?.type) ; else tenantApproval" style="font-size: x-small;">
												This document type don't have status
											</div>
											<ng-template #tenantApproval >
												<div style="margin: auto;">
													<button type="button" pButton [disabled]="isSaving || document?.tenantApprovalStatus =='approved'"
													 *ngIf="(['approved','pending'].includes(document?.tenantApprovalStatus))"
														class="p-button-raised p-button-rounded p-button-success p-float-right p-ml-2" icon="pi pi-check"
														pTooltip="Approve" tooltipPosition="top"
														(click)="approveRejectLeaseDocument('approve',document?.id,'tenant')"></button> 
													<button type="button" pButton class="p-button-raised p-button-rounded p-button-danger p-ml-2"
														icon="pi pi-times" pTooltip="Reject" tooltipPosition="top"
														(click)="approveRejectLeaseDocument('reject',document?.id,'tenant')" [disabled]="isSaving||document?.tenantApprovalStatus =='rejected'"
														*ngIf="(['rejected','pending'].includes(document?.tenantApprovalStatus))"></button>
												</div>
											</ng-template>
										</td>
										<!-- to add mor column to document lister if we are in leasing document  -->

									</ng-container>
									<td style="min-width: 60px; width: 60px;">
										<button type="button" (click)="deleteDocumentDialog(document.id,document.type)"
											class="btn btn-sm btn-outline-primary" [disabled]="isReadOnly || !canDeleteDocs">
											<i class="fa fa-trash"></i>
											<!-- <ng-container *ngIf="!onlyIcons"> Remove</ng-container> -->
										</button>
										<ng-container *ngIf="vendorDocuments || approveByActorType">
											<div *ngIf=" ( (document.refType !== 'journalPU') ||  ( document.refType==='journalPU' && (this.isPropertyManager || this.isPropertyAccountanat)))">
												<button type="button" pButton [disabled]="isSaving"
												 *ngIf="(!document?.approvalStatus || document?.approvalStatus !=='approved') || 
												 (document.type===documentTypeEnum.PurchaseInvoice && document.refType==='journalPU' && document?.approvalStatus==='approved' && !document.approved)"
													class="p-button-raised p-button-rounded p-button-success p-float-right p-ml-2" icon="pi pi-check"
													pTooltip="Approve" tooltipPosition="top"
													(click)="approveRejectDocument ('approve',document?.id)"></button> 
												<button type="button" pButton class="p-button-raised p-button-rounded p-button-danger p-ml-2"
													icon="pi pi-times" pTooltip="Reject" tooltipPosition="top"
													(click)="approveRejectDocument ('reject',document?.id)" [disabled]="isSaving"
													*ngIf="(!document?.approvalStatus || document?.approvalStatus !=='rejected') && allowReject"></button>
												<button type="button" pButton class="p-button-raised p-button-rounded p-button-warning p-ml-2"
													icon="pi pi-info" pTooltip="Approval History" tooltipPosition="top"
													(click)="showApprovalHistory(document)" [disabled]="isSaving"></button>
											</div>
										</ng-container>
										<p-badge [value]="document?.approvalStatus" [severity]="document?.approvalStatus==='approved' ? 'success' : 'warn'"
											*ngIf="!!document.approvalStatus">
										</p-badge>
									</td>
								</tr>
							</ng-template>
						</p-table>
					</div>
				</ng-template>
				<ng-template #noDoc>
					<br>
					<div class="clearfix">There are no uploaded documents.</div>
				</ng-template>
			</ng-template>
			<div class="p-pt-3" *ngIf="mandatoryDocumentList.length === 0  && !isLoading && haveMandatory">
				<p class="p-text-green">All required documents are uploaded</p>
			</div>
		</div>
	</div>
	<ng-template #genTemplates>
		<div class="modal-header">
			<h4 class="d-inline-block">Generate document templates</h4>
		</div>
		<div class="modal-body">

			<div class="col mt-2">
				<div class="row mt-1">
					<button type="button" class="btn btn-outline-primary float-right mr-1">
						Generate request to approve quotation</button>
				</div>

				<div class="row mt-1">
					<button type="button" class="btn btn-outline-primary float-right mr-1">
						Generate acknowledgement</button>
				</div>

				<div class="row mt-1">
					<button type="button" class="btn btn-outline-primary float-right mr-1">Generate lease agreement</button>
				</div>

				<div class="row mt-1">
					<button type="button" class="btn btn-outline-primary float-right mr-1">
						Bounced Cheque Notice</button>
				</div>

				<div class="row mt-1">
					<button type="button" class="btn btn-outline-primary float-right mr-1">
						HSE Notice</button>
				</div>

				<div class="row mt-1">
					<button type="button" class="btn btn-outline-primary float-right mr-1">
						Notice of Lease Renewal</button>
				</div>

				<div class="row mt-1">
					<button type="button" class="btn btn-outline-primary float-right mr-1">
						Notice of Service Charges Arrears</button>
				</div>

				<div class="row mt-1">
					<button type="button" class="btn btn-outline-primary float-right mr-1">
						Rent Arrears Notice</button>
				</div>

				<div class="row mt-1">
					<button type="button" class="btn btn-outline-primary float-right mr-1">
						Vacating Notice</button>
				</div>
			</div>

		</div>
		<div class="modal-footer">
		</div>
	</ng-template>
</div>
<div class="p-col-12" *ngIf="pageTitle === 'contactProfile'">
	<div class="p-col-12" *ngIf="!!header">
		<label class="d-inline-block float-left titleText  font-weight-bold p-text-white">{{header}}</label>
	</div>
	<div class="p-col-12 p-pt-6">
		<button pButton class="p-button-outlined " label="Upload Documents" (click)="uploadDocument()"
			*ngIf="!disableUpload" [disabled]="isReadOnly">
			<i class="pi pi-upload p-pr-3"></i>
		</button>
	</div>
	<div class="p-text-white">
		<div class="p-grid p-col-12">
			<div class="p-col-5">
				File Name
			</div>
			<div class="p-col-5">
				Type
			</div>
		</div>
		<div>
			<div *ngIf="filteredDocuments.length > 0 && documentTypes| async as dts">
				<div class="p-grid p-col-12" *ngFor="let document of filteredDocuments ">
					<div class="p-col-5">
						{{document.name}}
					</div>
					<div class="p-col-5">
						{{dts[document.type]?.description}}
					</div>
					<div class="p-col-2">
						<button class="float-right p-px-0 btn btn-sm" type="button"
							(click)="deleteDocumentDialog(document.id,document.type)" [disabled]="isReadOnly || !canDeleteDocs">
							<img src="/assets/icons/customer/trash_white.svg">
						</button>
					</div>
				</div>
			</div>
			<div *ngIf="filteredDocuments.length === 0">
				There are no uploaded documents.
			</div>
		</div>
	</div>
</div>
<p-dialog header="Document: {{openedDocument.name}}" *ngIf="displayFileViewer" [(visible)]="displayFileViewer"
	[style]="{width: '75vw'}" [baseZIndex]="100000" appendTo="body" [closable]="false"
	(onHide)="openedDocument = undefined;">
	<div *ngIf="isDocImage(openedDocument)" style="text-align: center; height: 60vh">
		<img [src]="openedDocumentUrl" style="max-width: 100%;">
	</div>
	<div *ngIf="isDocPDF(openedDocument)"
		[style]="(isDocumentDownloading | async) ?{height: '50vh', display: 'flex', 'justify-content': 'center', 'align-items': 'center'}:{}">
		<p-progressSpinner *ngIf='isDocumentDownloading | async'> </p-progressSpinner>
		<iframe [src]="openedDocumentUrl" *ngIf="!(isDocumentDownloading | async)" width="100%" style="height: 60vh"
			frameborder="0">
		</iframe>
	</div>
	<div *ngIf="!isDocPDF(openedDocument)&& !isDocImage(openedDocument)"
		style="text-align: center; height: 60vh; padding: 25vh">
		<br>
		<h3>can't Preview this type of documents</h3>
	</div>
	<p-footer class="p-text-right">
		<button pButton label="Cancel" class="p-button-outlined cancel"
			(click)="displayFileViewer=false;openedDocument = undefined;">
				<i class="pi p-1 pi-times"></i>
		</button>
		<button  pButton *ngIf="(openedDocument.type=='at' && openedDocument.refType=='journalPU')" (click)="changeDocumentType(openedDocument?.id)" label="Change to invoice type" class="p-button-outlined cancel"></button>
		<button pButton  [cdkCopyToClipboard]="openedDocument.url" label="Copy Link" (cdkCopyToClipboardCopied)=" success() ">
				<i class="pi p-1 pi-copy"></i>
		</button>
		<a pButton class="no-underline-link" label="Download" href="{{openedDocument.url}}" target="_blank">
				<i class="pi p-1 pi-download"></i>
		</a>
	</p-footer>
</p-dialog>
<app-document-uploader [maxFileSize]="maxFileSize" *ngIf="displayUploader && listerData" [display]="displayUploader"
	(hideEvent)="close()" [data]="listerData" [needNotificationRange]="needNotificationRange" [isLeasingDocuments]="leaseId?true:false"
	(uploadDocumentsEvent)="addedFile()"></app-document-uploader>
<p-dialog header="Reject Reason" [(visible)]="showReasonDialog" [style]="{width: '50vw'}" [autoZIndex]="true"
	modal="true" appendTo="body">
	<div class="p-grid p-col-12 p-py-0" >
		<div class="p-col-12 p-md-4">Reject Reason: <span class="p-text-danger"> *</span></div>
		<div class="p-text-bold p-col-12 p-md-8">
			<textarea [rows]="5" pInputTextarea autoWidth="false" [style]="{'width':'100%','display':leaseId?'none':'unset'}" [required]="true" #rejectReason
				name="rejectReason" placeholder="Reject Reason"></textarea>
		</div>
	</div>
	<p-footer class="p-text-right">
		<button pButton label="Cancel" class="p-button-outlined"
			(click)="rejectReason.value='';showReasonDialog=false"></button>
		<button pButton label="Submit" (click)="rejectDocument(rejectReason.value);rejectReason.value=''"
			class="p-ml-2"></button>
	</p-footer>
</p-dialog>
<p-dialog [header]="approveLeaseDocument?'Document Approval':'Document Rejection'" [(visible)]="approveLeaseDocument" [(visible)]="rejectLeaseDocument" [style]="{width: '50vw'}" [autoZIndex]="true"
	modal="true" appendTo="body">
	<div class="p-grid p-col-12 p-py-0" >
		<div *ngIf="approveLeaseDocument" class="p-col-12 p-md-4" [style]="{width:'100%'}">Are you sure you want to approve this document ?</div>
		<div *ngIf="rejectLeaseDocument" class="p-col-12 p-md-4" [style]="{width:'100%'}">Are you sure you want to reject this document ?</div>
	</div>
	<p-footer class="p-text-right">
		<button pButton label="Cancel" class="p-button-outlined"
			(click)="rejectLeaseDocument=false;approveLeaseDocument=false"></button>
		<button *ngIf="leaseId" pButton [label]="approveLeaseDocument?'Approve':'Reject'" (click)="submitApproveRejectLeaseDocument(approveLeaseDocument?'approve':'reject')"
			class="p-ml-2"></button>
	</p-footer>
</p-dialog>
<p-dialog header="Approval History" [(visible)]="showApprovalHistoryDialog" [style]="{width: '60vw'}" [autoZIndex]="true"
	modal="true" appendTo="body">
	<div class="p-col-12 p-px-0">
		<div class="p-text-bold p-mb-2">Approval History:</div>
		<div class="p-p-3 text-danger" *ngIf="!selectedDocumentHistory?.length">
			The file has no history approval
		</div>
		<p-table *ngIf="selectedDocumentHistory?.length" [scrollable]="true" [value]="selectedDocumentHistory"
			scrollHeight="flex" [sortOrder]="-1" styleClass="p-datatable-gridlines p-datatable-striped">
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th class="p-py-0 text-center"></th>
					<th class="p-py-0 text-center">Action Date</th>
					<th class="p-py-0 text-center">CM Action</th>
					<th class="p-py-0 text-center">Avvountant Action</th>
					<th class="p-py-0 text-center">User</th>
					<th class="p-py-0 text-center">Remarks</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-row let-i="rowIndex">
				<tr style="font-size: 0.86rem;">
					<td class="p-p-0">{{i+1}}</td>
					<td class="p-p-0"> {{row.date}} </td>
					<td class="p-p-0"> {{row.status}} </td>
					<td class="p-p-0"> {{row.finalApproval}} </td>
					<td class="p-p-0"> {{row.user}} </td>
					<td class="p-p-0"> {{row.remark}} </td>
				</tr>
			</ng-template>
		</p-table>
	</div>
	<p-footer class="p-text-right">
		<button pButton label="Close" class="p-button-primary"
			(click)="showApprovalHistoryDialog=false;"></button>
	</p-footer>
</p-dialog>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" acceptButtonStyleClass="p-button-success"
	rejectButtonStyleClass="p-button-danger"></p-confirmDialog>