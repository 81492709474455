import { SendLeasePaymentSchedulePDFBodySchema, SendToType } from './../../../models/api/leasePaymentSchedulePdf/sendLeasePaymentSchedulePdf.api.model';
import { LeasePrep } from '../../../models/leasePrep.model';
import { LeaseApprovalHistory } from '../../../models/leaseApprovalHistory.model';
import {
	AssignableUnit, AssignUnitsResult,
	LeaseDetails, LeaseDocument, LeasePaymentScheduleCX,
	LeaseServiceChargingScheduleEntry,
	leaseTotalsSchema
} from '../../../models/lease.pg.model';

import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { LeasePaymentScheduleEntry, LeaseTuple, LeaseVM } from '../../../models/lease.pg.model';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CustomToastrService } from 'src/app/shared/services/customToastr.service';
import { GeneralSearchModel } from '../../../models/generalSearch.model';
import { LeaseNonPaidPeriod, NonPaidPeriodEntry } from '../../../models/leaseNonPaidPeriod.pg.model';
import { ContactType } from '../../../models/requestDetails.models';
import { ContactsService } from './contacts.service';
import { ContactReferenceService } from './contact-reference.service';
import { DocumentPG, DocumentTypeValues } from '../../../models/document.pg.model';
import { apiErrorHandler } from './shared/errors';
import { environment } from '../environments/environment';
import { CustomMessageService } from './shared/services/customPriemngMessage.service';
import { PutLeaseDatesBodySchema, PutLeaseVatsSchema, TerminateLeaseRequestBody } from 'models/api/lease/lease.model';
import {GenerateLeaseDocumentRequestBody} from "models/api/lease/leaseDocument.models";
import { DocumentSettingRefType } from 'models/api/document/decomuntSettings.api.model';

const BASE_API_URL = environment.apiURL;

@Injectable( {
	providedIn: 'root'
} )
export class LeasePGService {

	private LEASE_API = `${ BASE_API_URL }/lease`;

	constructor (
			private http: HttpClient,
			private toastrService: CustomToastrService,
			private messageService: CustomMessageService,
			private contactService:ContactsService,
			private contactReferenceService:ContactReferenceService,
			) { }

	public getBy = async ( key?: string, value?: string ): Promise<LeaseVM[]> => {
		try {
			const url = `${ this.LEASE_API }${ ( !!key && !!value ) ? '/' + key + '/' + value : '' }`;
			const result = await firstValueFrom( this.http.get<LeaseVM[]>( url ) );
			return result;
		} catch ( error ) {
			this.toastrService.error( 'Failed to fetch Lease' );
			console.error( error );
			throw error;
		}
	}

	public post = async ( payload: LeaseVM ): Promise<any> => {
		try {
			const result = await firstValueFrom( this.http.post<LeaseTuple>( this.LEASE_API, payload ) );
			return result;
		} catch ( error ) {
			this.toastrService.error( error.error.message || 'Failed to add Lease' );
			console.error( error );
			throw error;
		}
	}

	public put = async (id: string | number, payload: Partial<LeaseTuple>, shouldCheckFinancial = true) => {
		if (payload.id && payload.id !== id) throw new Error('Id mismatch...');
		payload.deposit = payload.deposit ?? 0;
		payload.annualRent = payload.annualRent ?? 0;
		payload.installments = payload.installments ?? 0;
		payload.vatPercentage = payload.vatPercentage ?? 0;
		try {
			const url = `${this.LEASE_API}/${id.toString().trim()}`;
			await firstValueFrom(this.http.put<LeaseTuple>(url, { ...payload, shouldCheckFinancial }));
		} catch (error) {
			this.messageService.error({ message: `Failed to update Lease`});
			console.error(error);
			throw error;
		}
	}

	public deactivateLease = async ( id: string | number, payload: Partial<LeaseTuple> ) => {
		if ( payload.id && payload.id !== id ) throw new Error( 'Id mismatch...' );
		try {
			const url = `${ this.LEASE_API }/deactivated/${ id.toString().trim() }`;
			await firstValueFrom( this.http.put<LeaseTuple>( url, payload ) );
		} catch ( error ) {
			this.toastrService.error( 'Failed to update Lease' );
			console.error( error );
			throw error;
		}
	}

	public activateLease = async ( id: string | number, payload: Partial<LeaseTuple> ) => {
		if ( payload.id && payload.id !== id ) throw new Error( 'Id mismatch...' );
		try {
			const url = `${ this.LEASE_API }/activated/${ id.toString().trim() }`;
			await firstValueFrom( this.http.put<LeaseTuple>( url, payload ) );
		} catch ( error ) {
			this.toastrService.error( 'Failed to update Lease' );
			console.error( error );
			throw error;
		}
	}

	public delete = async ( id: string | number ): Promise<LeaseTuple> => {
		try {
			const url = `${ this.LEASE_API }/${ id.toString().trim() }`;
			const result = await firstValueFrom( this.http.delete<LeaseTuple>( url ) );
			return result;
		} catch ( error ) {
			this.toastrService.error( 'Failed to fetch Lease' );
			console.error( error );
			throw error;
		}
	}

	public search = async ( searchObject: GeneralSearchModel ): Promise<LeaseTuple[]> => {
		try {
			const url = `${ this.LEASE_API }/search`;
			const result = await firstValueFrom( this.http.post<LeaseTuple[]>( url, searchObject ) );
			return result;
		} catch ( error ) {
			this.toastrService.error( 'Failed to fetch Note' );
			console.error( error );
			throw error;
		}
	}

	public bulkUpsert = async ( leases: LeaseTuple[] ) => {
		try {
			const result = await firstValueFrom( this.http.post<LeaseTuple[]>( `${ this.LEASE_API }/bulk`, { payload: leases } ) );
		} catch ( error ) {
			this.toastrService.error( 'Failed to create lease' );
			console.error( error );
			throw error;
		}
	}

	public assignUnitToLease = async ( unit: string, lease: string ) => {
		try {
			const result = await firstValueFrom( this.http.post( this.LEASE_API + '/assignUnitToLease', { unit, lease } ) );
			return result;
		} catch ( error ) {
			this.toastrService.error( 'Failed to assign unit to lease' );
			console.error( error );
			throw error;
		}
	}
	public sendLeaseDocument = async (documentId:string,contactType:ContactType,leaseId:string, startDate?:string,endDate?:string, specialDocumentType?:string, unitId?:string, documentType?:DocumentTypeValues)=>{
		try {
			await firstValueFrom( this.http.post( BASE_API_URL + '/v2/send-lease-doc',
			{
				contactType ,
				document: documentId,
				lease:leaseId,
				startDate,
				endDate,
				specialDocumentType,
				unitId,
				documentType,
			} ) );
			const emailText= await this.getEmailText(contactType, leaseId)
			this.messageService.success({ message: `Document Sent Successfully to ${emailText}`});
		} catch ( error ) {
			console.error( error );
			this.messageService.error({ message: `Failed to send email, please try again later`});
			throw error;
		}
	}
	public unAssignUnitFromLease = async ( unit: string, lease: string ) => {
		try {
			const result = await firstValueFrom( this.http.post( this.LEASE_API + '/unAssignUnitFromLease', { unit, lease } ) );
			return result;
		} catch ( error ) {
			this.toastrService.error( 'Failed to unAssign unit from lease' );
			console.error( error );
			throw error;
		}
	}

	public getLeaseAssignableUnits = async ( community: string, unit: string, lease: LeaseVM ): Promise<any[]> => {
		try {
			const result = await firstValueFrom( this.http.post<any>( this.LEASE_API + '/leaseAssignableUnits', { community, unit, lease } ) );
			return result;
		} catch ( error ) {
			this.toastrService.error( 'Failed to unAssign unit from lease' );
			console.error( error );
			throw error;
		}
	}

	public makeLeaseCurrent = async ( unit: string, lease: string ) => {
		try {
			await firstValueFrom( this.http.post( this.LEASE_API + '/makeCurrent', { unit, lease } ) );
		} catch ( error ) {
			this.toastrService.error( 'Failed to make this lease current.' );
			console.error( error );
			throw error;
		}
	}

	public savePaymentSchedule = async ( lease: string, paymentSchedule: LeasePaymentScheduleEntry[] ) => {
		try {
			await firstValueFrom( this.http.post( this.LEASE_API + '/paymentSchedule', { lease, paymentSchedule } ) );
		} catch ( error ) {
			this.toastrService.error( 'Failed to save the payment schedule!!' );
			console.error( error );
			throw error;
		}
	}

	public terminateLease = async ( propertyGroupId: string, leaseId: string, terminateLeaseSchema: TerminateLeaseRequestBody ) => {
		try {
			await firstValueFrom( this.http.post( `${BASE_API_URL}/v2/property-groups/${propertyGroupId}/leases/${leaseId}/termination`, terminateLeaseSchema ) )
			.catch(apiErrorHandler(this.messageService, {accessDeniedExtraMessage:'Failed to terminate this lease.'}));
		} catch ( error ) {
			error?.error?.data.forEach(message=>{
				this.messageService.error( { message , keepPrevious: true, sticky: true } )
			})
			console.error( error );
			throw error;
		}
	}


	public savePaymentScheduleChanges = async ( propertyGroupId:string,leaseId: string, paymentSchedule: LeasePaymentScheduleEntry[] ) => {
		try {
			await firstValueFrom( this.http.put( `${BASE_API_URL}/v2/property-groups/${propertyGroupId}/leases/${leaseId}/payment-schedule`, paymentSchedule ,{} ) )
				.catch(apiErrorHandler(this.messageService, {accessDeniedExtraMessage:'payment schedule'}));
		} catch ( error ) {
			error?.error?.data.forEach(message=>{
				this.toastrService.error( message, 'Error!' );
			})
			console.error( error );
			throw error;
		}
	}
	public updateLeaseDates = async ( propertyGroupId:string,leaseId: string, leaseDates: PutLeaseDatesBodySchema ) => {
		try {
			await firstValueFrom( this.http.put( `${BASE_API_URL}/v2/property-groups/${propertyGroupId}/leases/${leaseId}/dates`, leaseDates,{} ) )
				} catch ( error ) {
			error?.error?.data.forEach(message=>{
				this.messageService.error( { message , keepPrevious: true, sticky: true } )
			})
			console.error( error );
			throw error;
		}
	}

	public updateLeaseVats = async ( propertyGroupId:string, leaseId: string, leaseVats: PutLeaseVatsSchema ) =>{
		await firstValueFrom(
			this.http.put( `${BASE_API_URL}/v2/property-groups/${propertyGroupId}/leases/${leaseId}/vats`, leaseVats )
		).catch(apiErrorHandler(this.messageService, {accessDeniedExtraMessage:'Fill to update VATs'}));
	}

	public signContractV2 = async ( propertyGroupId:string,leaseId: string) => {
		try {
			await firstValueFrom( this.http.post( `${BASE_API_URL}/v2/property-groups/${propertyGroupId}/leases/${leaseId}/sign` ,{}) )
		} catch ( error ) {
			error?.error?.data.forEach(message=>{
				this.messageService.error( { message , keepPrevious: true, sticky: true } )
			})
			console.error( error );
			throw error;
		}
	}

	async postLeaseV2(propertyGroupId:string, leaseId: string) {
		await firstValueFrom( this.http.post( `${BASE_API_URL}/v2/property-groups/${propertyGroupId}/leases/${leaseId}/post` ,{}) )
			.catch(apiErrorHandler(this.messageService, {accessDeniedExtraMessage:'post lease'} ) );
	} 

	public getPaymentSchedule = async ( propertyGroupId:string,leaseId: string ) => {
		try {
			return await firstValueFrom( this.http.get<LeasePaymentScheduleEntry[]>( `${BASE_API_URL}/v2/property-groups/${propertyGroupId}/leases/${leaseId}/payment-schedule`, {} ) )
				.catch(apiErrorHandler(this.messageService, {accessDeniedExtraMessage:'payment schedule'}));
		} catch ( error ) {
			error?.error?.data.forEach(message=>{
				this.toastrService.error( message );
			})
			console.error( error );
			throw error;
		}
	}

	public voidLease = async ( lease: string, makeUnSigned: boolean ) => {
		try {
			await firstValueFrom( this.http.post( this.LEASE_API + '/voidLease', { lease, makeUnSigned } ) );
		} catch ( error ) {
			this.toastrService.error( 'Failed to void the Lease!!' );
			console.error( error );
			throw error;
		}
	}

	public downloadInvoice = ( projectId: string, journalId: string, leaseId: string ): Observable<any> => {
		try {
			return this.http.post(`${ BASE_API_URL }/v2/property-groups/${projectId}/leases/${leaseId}/invoices/${journalId}/download`,{},{
				responseType: 'blob',
				headers: new HttpHeaders().append( 'Accept', 'application/pdf' ),
				observe: 'response',
			} );
		} catch ( error ) {
			apiErrorHandler(this.messageService, {accessDeniedExtraMessage:'downloading invoice'})(error);
			console.error( error );
			throw error;
		}
	}

	public downloadAcknowledgement = ( community: string, lease: string, acknowledgementId?: string ): Observable<any> => {
		try {
			return this.http.post( `${ BASE_API_URL }/leaseAcknowledgementPdf`, { community, lease, acknowledgementId }, {
				responseType: 'blob',
				headers: new HttpHeaders().append( 'Accept', 'application/pdf' ),
				observe: 'response',
			} );
		} catch ( error ) {
			this.toastrService.error( 'An error occurred while downloading the acknowledgement!!' );
			console.error( error );
			throw error;
		}
	}

	async generateDocument( communityId: string, leaseId: string, body: GenerateLeaseDocumentRequestBody ): Promise<HttpResponse<Blob>> {

		try {
			return await firstValueFrom(this.http.post(`${ BASE_API_URL }/v2/property-groups/${communityId}/leases/${leaseId}/documents`, body, {
				responseType: 'blob',
				headers: new HttpHeaders().append( 'Accept', 'application/pdf' ),
				observe: 'response',
			} ) )
		} catch ( error ) {
			if(error.status === 405){
				this.messageService.error( { 
					message: 'This Document is disabled, please visit the settings page to enable this feature',
					} );
			}
			console.error( error );
			throw error;
		}
	}

	public sendLeasePaymentSchedulePdf = ( community: string, lease: string, sendTo: SendToType ): Observable<any> => {
		try {
			const payload: SendLeasePaymentSchedulePDFBodySchema = {community, lease, sendTo}
			return this.http.post( `${ BASE_API_URL }/sendLeasePaymentSchedulePdf`, payload );
		} catch ( error ) {
			this.toastrService.error( 'An error occurred while sending Lease Paymment Schedule To Tenant!!' );
			console.error( error );
			throw error;
		}
	}

	public sendLeaseInvoicePdfToTenant = ( projectId: string, leaseId: string, journalId?: string ): Observable<any> => {
		try {
			return this.http.post( `${ BASE_API_URL }/sendLeaseInvoicePdfToTenant`, { projectId, journalId, leaseId } );
		} catch ( error ) {
			this.toastrService.error( 'An error occurred while sending Lease Acknowledgement To Tenant!!' );
			console.error( error );
			throw error;
		}
	}

	public sendLeaseAcknowledgementPdfToLandlord = ( community: string, lease: string, acknowledgementId?: string ): Observable<any> => {
		try {
			return this.http.post( `${ BASE_API_URL }/sendLeaseAcknowledgementPdfToLandlord`, { community, lease, acknowledgementId } );
		} catch ( error ) {
			this.toastrService.error( 'An error occurred while sending Lease Acknowledgement To Landlord!!' );
			console.error( error );
			throw error;
		}
	}
	public sendLeaseInvoicePdfToLandlord = ( projectId: string, leaseId: string, journalId?: string ): Observable<any> => {
		try {
			return this.http.post( `${ BASE_API_URL }/sendLeaseInvoicePdfToLandlord`, { projectId, journalId, leaseId } );
		} catch ( error ) {
			this.toastrService.error( 'An error occurred while sending Lease Acknowledgement To Landlord!!' );
			console.error( error );
			throw error;
		}
	}

	public getLeaseVMByCommunities = async ( communities: string[], forAnnouncements = false ) => {
		try {
			return await firstValueFrom( this.http.post<any[]>( `${ BASE_API_URL }/lease/communities`, { communities, forAnnouncements } ) );
		} catch ( error ) {
			this.toastrService.error( 'Failed to fetch Landlords' );
			console.error( error );
			throw error;
		}
	}

	public saveServiceChargingSchedule = async ( lease: string, serviceChargingSchedule: LeaseServiceChargingScheduleEntry[] ) => {
		try {
			await firstValueFrom( this.http.post( this.LEASE_API + '/ServiceChargingSchedule', { lease, serviceChargingSchedule } ) );
		} catch ( error ) {
			this.toastrService.error( 'Failed to save the payment schedule!!' );
			console.error( error );
			throw error;
		}
	}

	public getServiceChargingScheduleByLease = async ( leaseId: string ) => {
		try {
			return await firstValueFrom( this.http.get( BASE_API_URL + '/serviceCharging/' + leaseId ) );
		} catch ( error ) {
			this.toastrService.error( 'Failed to fetch  service charging schedule!!' );
			console.error( error );
			throw error;
		}
	}

	public getDraftLeasesByUnitId = async ( unitId: string ) => {
		try {
			return await firstValueFrom( this.http.get( this.LEASE_API + '/draft/unit/' + unitId ) );
		} catch ( error ) {
			this.toastrService.error( 'Failed to fetch Draft Leases' );
			console.error( error );
			throw error;
		}
	}


	async getLeaseApprovalHistoriesByLease ( leaseId: string ) {
		try {
			const result = ( await firstValueFrom( this.http.get<LeaseApprovalHistory>( BASE_API_URL + '/leaseApprovalHistory/' + leaseId ) ) );
			this.toastrService.success( 'The lease Approved History is fetched successfully' );

			return result
		} catch ( error ) {
			this.toastrService.error( 'Failed to save the lease Approval History !!' );
			console.error( error );
			throw error;
		}
	}

	async updateLeaseApprovalHistoryStatus ( leaseId: string, status ) {
		try {
			const result = ( await firstValueFrom( this.http.put<LeaseApprovalHistory>( BASE_API_URL + `/leaseApprovalHistory/${ leaseId }/${ status }`, {} ) ) );
			this.toastrService.success( `The Offer to Lease is ${ status } Successfully` );
			return result
		} catch ( error ) {
			this.toastrService.error( 'Failed to save the payment schedule!!' );
			console.error( error );
			throw error;
		}
	}



	async checkApprovementOfDraftLease ( contract: LeasePrep, requireAllLandlordsApproval: boolean, offerToLeaseRequiresApproval: boolean ) {
		try {
			const result: any = ( await firstValueFrom( this.http.post( this.LEASE_API + `/checkApprovementOfDraftLease`, { contract, requireAllLandlordsApproval, offerToLeaseRequiresApproval } ) ) );
			if ( result?.isPending )
				this.toastrService.error( `This contract is not approved by all landlords.` );
			else if ( result?.isDecline )
				this.toastrService.error( 'There the landlord has declined this contract.' );
			else
				return true
		} catch ( error ) {
			this.toastrService.error( 'Failed to send draft lease to tenants!!' );
			console.error( error );
			throw error;
		}
	}


	public cancelReservedLease = async ( lease: LeaseVM, journals ): Promise<LeaseTuple> => {
		try {
			const result: any = await firstValueFrom( this.http.post( `${ this.LEASE_API }/cancelReservedLease/${ lease.project }/${ lease.id }`, { journals } ) );
			return result;

		} catch ( error ) {
			this.toastrService.error( 'Failed to cancel reserved lease' );
			console.error( error );
			throw error;
		}
	}

	addMessage ( { severity, sticky = false, summary = '', key = '', detail = '', life = 2000 } ) {
		this.messageService.add( { severity, sticky, summary, life, key, detail } );
	}

	public saveNonPaidPeriods = async (leaseId:string,nonPaidPeriods:NonPaidPeriodEntry[]) => {
		try {
			await firstValueFrom( this.http.post( `${ this.LEASE_API }/nonPaidPeriod`, { leaseId,nonPaidPeriods } ) );
			this.messageService.success( { message: 'Non Paid Periods successfully saved.' } );
		} catch ( error ) {
			let message: string = extractErrorMessage(error);
			message = !message ? 'Failed to post non paid period to lease lease' : message;
			this.messageService.error( { sticky: true, message } );
			console.error( error );
			throw error;
		}
	}

	public getLeaseNonPaidPeriods = async (  lease: string ): Promise<LeaseNonPaidPeriod[]> => {
		try {
			const result = await firstValueFrom( this.http.get<any>( `${ this.LEASE_API }/nonPaidPeriod/${lease}` ) );
			return result;
		} catch ( error ) {
			let message: string = extractErrorMessage(error);
			message = !message ? 'Failed to fetch Non Paid Period for this lease' : message;
			this.messageService.error( { sticky: true, message } );
			console.error( error );
			throw error;
		}
	}

	async getEmailText(contactType: string, leaseId:string) {
		const [lease] = await this.getBy('id',leaseId)
		const tenantFunction = await this.contactReferenceService.getBy('refId', lease.id);
		const contacts = (await this.contactService.getcontacts());
		const tenants = tenantFunction.map(tf => contacts[tf.contact]);
		const tenantEmails = tenants.map(t => t.emails.find(em => em.isDefault).email);
		let landlords = [];
		if(!Array.isArray(lease.landlords)){
			landlords = JSON.parse(lease.landlords).map(landlord => contacts[landlord]);
		} if(Array.isArray(lease.landlords)){
			landlords = lease.landlords.map(landlord => contacts[landlord]);
		}
		const landlordsEmails = landlords.map(l => l.emails.find(em => em.isDefault).email);
		const emails = contactType === 'landlord' ? landlordsEmails : tenantEmails;
		const emailsText = emails.join(', ');
		return emailsText;
	}

	public getPaymentScheduleByUnitId = async ( unitId: string ) => {
		try {
			const res= await firstValueFrom( this.http.get<LeasePaymentScheduleCX[]>( `${BASE_API_URL}/payment-schedule/${unitId} `) );
			return res ||[];
		} catch ( error ) {
			this.toastrService.error( 'Failed to fetch Payment Schedule' );
			console.error( error );
			throw error;
		}
	}

	public getLatestOfferToLeaseDoc= async(lease:string)=>{
		try {
			return await firstValueFrom( this.http.get<DocumentPG>( `${ BASE_API_URL }/latest-offer-lease-doc/${ lease }` ));
		} catch ( error ) {
			this.toastrService.error( 'Failed to fetch document from the server' );
			console.error( error );
			throw error;
		}
	}
	public isDraftLease= async(contact:string)=>{
		try {
			const isDraft= await firstValueFrom( this.http.get<{isDraft:boolean}>( `${ BASE_API_URL }/Check-draft-lease/${ contact }` ));
			return isDraft;
		} catch ( error ) {
			this.toastrService.error( 'Failed to fetch lease status from the server' );
			console.error( error );
			throw error;
		}
	}

	public getLeaseTotals = async (propertyGroupId:string,leaseId: string): Promise<leaseTotalsSchema> => {
		try {
			return await firstValueFrom( this.http.get<leaseTotalsSchema>(`${ BASE_API_URL }/v2/property-groups/${propertyGroupId}/leases/${leaseId}/totals`) );
		} catch ( error ) {
			apiErrorHandler(this.messageService, {accessDeniedExtraMessage:'lease contract'});
		}

    }

	async getLeaseDetails(propertyGroupId:string, leaseId: string): Promise<LeaseDetails> {
		return await firstValueFrom(this.http.get<LeaseDetails>(`${ BASE_API_URL }/v2/property-groups/${propertyGroupId}/leases/${leaseId}/details` ) )
			.catch(apiErrorHandler(this.messageService, {accessDeniedExtraMessage:'lease details'}));
    }

	async editEjariNumber(propertyGroupId:string, leaseId: string, ejariNumber:string) {
		return await firstValueFrom(this.http.put(`${ BASE_API_URL }/v2/property-groups/${propertyGroupId}/leases/${leaseId}/ejari` ,{ejariNumber}) )
			.catch(apiErrorHandler(this.messageService, {accessDeniedExtraMessage:'Ejari Number'}));
    }

	async editContractNumber(propertyGroupId:string, leaseId: string, contractNumber:string) {
		return await firstValueFrom(this.http.put(`${ BASE_API_URL }/v2/property-groups/${propertyGroupId}/leases/${leaseId}/contract-number` ,{contractNumber}) )
			.catch(apiErrorHandler(this.messageService, {accessDeniedExtraMessage:'Lease Number'}));
	}
	async getAssignableUnits(propertyGroupId:string, leaseId: string): Promise<AssignableUnit[]> {
		return await firstValueFrom(this.http.get<AssignableUnit[]>(`${ BASE_API_URL }/v2/property-groups/${propertyGroupId}/leases/${leaseId}/assignable-units` ) )
			.catch(apiErrorHandler(this.messageService, {accessDeniedExtraMessage:'assignable units'}));
    }

	async getLeasingDocumentsForPropertyGroup(propertyGroupId:string, refType: string): Promise<LeaseDocument[]> {
		return await firstValueFrom(this.http.get<LeaseDocument[]>(`${ BASE_API_URL }/v2/property-groups/${propertyGroupId}/lease-documents/${refType}` ) )
			.catch(apiErrorHandler(this.messageService, {accessDeniedExtraMessage:'assignable units'}));
    }

	async getLeasingDocumentInfoForPropertyGroup(
		propertyGroupId: string,
		refType: DocumentSettingRefType,
	): Promise<[LeaseDocument[], LeaseDocument[], LeaseDocument[]]> {
		const documents = await this.getLeasingDocumentsForPropertyGroup(propertyGroupId, refType);
		const requiredDocuments = documents.filter(doc => doc.required === true);
		const optionalDocuments = documents.filter(doc => doc.required === false);
		return [documents, requiredDocuments, optionalDocuments];
	}

	async setAssignedUnits(propertyGroupId:string, leaseId: string, unitsIds: string[]): Promise<AssignUnitsResult> {
		const payload = unitsIds.map( unitId => ( { id: unitId } ) );
		return await firstValueFrom(this.http.post<AssignUnitsResult>(`${ BASE_API_URL }/v2/property-groups/${propertyGroupId}/leases/${leaseId}/units`, payload ) )
			.catch(apiErrorHandler(this.messageService, {accessDeniedExtraMessage:'assign units'} ) );
    }

}
function extractErrorMessage(error: any) {
	const pattern = /BadRequestError:\s*([\s\S]*?)(?=<br>\s*&nbsp;)/;
	const match = error?.error?.match(pattern);
	let errorMessage = '';
	if (match) {
		errorMessage = match[1]?.replace('<br>', '');
	}
	return errorMessage;
}
