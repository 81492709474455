export enum ContactTypes {
	Developer = 'Developer',
	ClientCompany = 'Client Company',
	InternalCompany = 'Internal Company',
	Individual = 'Individual',
	VendorCompany = 'Vendor Company'
  }

export enum ContactFunctionsCodeEnum {
	CommunityManager = 'cm',
	CommunicationsOfficer = 'co',
	PropertyCoordinator = 'pc',
	PropertyManager = 'pm',
	DocumentControl = 'dc',
	Household = 'household',
	InvoiceTarget = 'invoiced',
	Landlord = 'landlord',
	CommunityAccountingManager = 'cam',
	ProjectAccountant = 'pa',
	Tenant = 'tenant',
	PrimaryAgent = 'agentPrimary',
	Agent = 'agent',
	Guest = 'guest',
	PRCreator = 'prc',
	POCreator = 'poc',
	PRApprover = 'pra',
	POApprover = 'poa',
	PurchaseInvoicesCreator = 'ic',
	PurchaseInvoicesPoster = 'ip',
	InvoicesReverser = 'ir',
	SalesCreator = 'sacr',
	QuotesApprover = 'saqa',
	SalesInvoicesCreator = 'saic',
	SalesInvoicesIssuer = 'saii',
	SalesCreditNotesCreator = 'sacnc',
	SalesCreditNotesIssuer = 'sacni',
	SalesReceiptsCreator = 'sarc',
	SalesReceiptsIssuer = 'sari',
	LeadsCreator = 'salc',
	UnitFinancialsCreateBulkInvoices = 'ufcpi',
	UnitFinancialsCreateBulkReceipts = 'ufcpr',
	UnitFinancialsMollakInvoices = 'ufmi',
	UnitFinancialsMollakReceipts = 'ufmr',
	UnitFinancialsViewSCPositionStatement = 'ufscps',
	UnitFinancialsCreateReceiptAndAcknowledgement = 'ufcra',
	UnitFinancialsPostAllowance = 'ufpa',
	UnitFinancialsTransferBalance = 'uftb',
	UnitFinancialsAuditTrail = 'ufat',
	UnitFinancialsViewSCStatement = 'ufscs',
	GeneralLedgerCreateManualJournal = 'glcmj',
	GeneralLedgerViewHistory = 'glvh',
	CashChequeMovementsTransferCash = 'ccmtc',
	CashAndChequeMovementsReconcile = 'ccmr',
	CashAndChequeMovementsUploadFile = 'ccmuf',
	AdjustmentsAdjust = 'ada',
	AdjustmentsViewDetails = 'advd',
	ReportsAnnualFinancialStatementBook = 'reafsp',
	ReportsTrialBalance = 'retb',
	ReportsProfitAndLoss = 'repl',
	ReportsVATLiability = 'revl',
	ReportsJournalReport = 'rejr',
	ReportsGFAndRFReport = 'regfrf',
	ReportsGeneralLedgerExport = 'regle',
	ReportsAgedCreditorsReport = 'reacr',
	ReportsPositionStatement = 'reps',
	FinancialSettingsGeneralSettings = 'fsgs',
	FinancialSettingsCommunityAccountsAdd = 'fscaa',
	FinancialSettingsCommunityAccountsEdit = 'fscae',
	FinancialSettingsCommunityAccountsDelete = 'fscad',
	EditContactDetails = 'cd',
	ClientDocumentControl = 'cdc',
	ReportsCashFlowStatement = 'recf',
	PropertyGroupProfileEdit = 'pgpe',
	PropertyGroupCreate = 'pgc',
	ReportsBalanceSheet = 'rebs',
	BudgetsettingasExecuting = 'csbae',
	BudgetCreation = 'crb',
	Procurer = 'proc',
	PurchaseReversing = 'revp',
	PurchaseVoiding = 'voip',
	PurchaseInvoiceVoiding = 'voipi',
	PurchasePaymentVoiding = 'voipp',
	VoidPurcahseCreditNote = 'vopucn',
	CreateAndEditReportsandDashboards = 'dashboardedit',
	VoidSale = 'vosa',
	VoidSaleInvoice = 'vosain',
	VoidSaleReceipt = 'vosarc',
	VoidSaleCreditNote = 'vosacn',
	VoidUtilityLedgerReceipt = 'voulrc',
	VoidUtilityLedgerInvoice = 'voulin',
	VoidUtilityLedgerCreditNote = 'voulcn',
	VoidUtilityLedgerSecurityDeposit = 'voulscdp',
	VoidServiceChargeInvoice = 'voscin',
	VoidServiceChargeReceipt = 'voscrc',
	VoidServiceChargeCreditNote = 'vosccn',
	VoidLeasingLedgerInvoice = 'volain',
	VoidLeasingLedgerPayment = 'volapa',
	VoidLeasingLedgerSecurityDeposit = 'volascdp',
	RequestsEMailsRecieve = 'rer',
	CollectionsDashboardAccess = 'cda',
	SiteContact = 's-con',
	LegalHandler = 'lh',
	MigrationAccess = 'ma',
	AuditLogViewer = 'alv',
	PropertyGroupSettingsEdit = 'pgse',
	RequestsReadonly = 'requestro',
	VendorsCreatorAndAdder = 'vca',
	propertyGroupDeveloper = 'pgdeveloper',
	ApproveVoidingRequest = 'avr',
	RejectVoidingRequest = 'rvr',
	PurchaseUnApprove = 'pu-unapprove',
	EditProcurementSettings = 'eps',
	VoidServiceChargeReceiptReversal = 'voscrrc',
	EditRequestPaymentStatus = 'ps',
	ExternalPartyContact = 'epc',
	ShowVATConsultantReportOnlyExternalConsultants = 'svcr',
	PerformActionsOnMajorWorkPermits = 'mjwpa',
	LegalNoticeReminderLog = 'lnrl',
	UnitFinancialsFlushSC = 'uffsc',
	ReceiveVendorEmails = 'rcvml',
	AllowFlashSales = 'alfs',
	AllowFlashPurchases = 'alfp',
	EditFlashSettings = 'edfs',
	VoidServiceChargePenaltyReversal = 'voscpe',
	Clientcontractterms = 'cct',
	MergePurchases = 'mrgps',
	MergePurchaseInvoices = 'mrgpis',
	ReversePurchaseInvoiceCreditNote = 'revpicn',
	AllowEditPMSettings = 'aele',
	ReceiveDocumentsExpiryNotifications = 'rcdexn',
	GeneralLedgerFlushGL = 'glfgl',
	AllowDeleteDocuments = 'addoc',
	AllowDeleteDocumentsGlobally = 'addocg',
	AccessAnnouncements = 'cvanng',
	AccessVisitors = 'cvvg',
	RentalRollReport = 'rernro',
	AccessLostAndFounds = 'cvlst',
	FacilityManagementReport = 'fmr',
	RentalAmountsReceivedReport = 'ramr',
	ExecutiveSummaryReport = 'esr',
	AccessContactEditLogs = 'acel',
	Resident = 'rsdnt',
	OccupancyReport = 'reocr',
	CollectionManager = 'colma',
	CollectorOfficer = 'coloff',
	TransferEquity = 'cte',
	ReportsAgedDebtorsReport = 'readr',
	ReportsUnitsInvoicesAndReceipts = 'cvuirr',
	ReportsExpenseDistributionStatement = 'expdis',
	ReportsVATInputAndOutput = 'rvtin',
	ReportsIncomeDistribution = 'reindis',
	ReportsReconciliation = 'rerecon',
	ReportsBudgetsvsActuals = 'rebds',
	MergeSales = 'mrgsa',
	MergeSalesInvoices = 'mrgsain',
	AllowActivatingAndDeactivatingLeases = 'aadl',
	AllowVendorsBulkUpload = 'avbup',
	EnableTenantsExportButton = 'eteb',
	EnableLandlordsExportButton = 'eleb',
	ReportsPayableAccountsStatement = 'repas',
	AllowDashboardAccess = 'csed',
	FinanceManager = 'fm',
	AssetPortfolioManager = 'apm',
	PurchasePaymentCreate = 'ppc',
	ReportsVendorLedger = 'repvr',
	CanViewtheproofofpaymentrequests = 'cvp',
	CanEdittheproofofpaymentrequests = 'cep',
	PurchaseInvoiceAuditLog = 'vial',
	OwnersCommitteeMember = 'oa',
	Lawyer = 'law',
	RestrictedUser = 'rstusr',
	RoleSupportUser = 'role-support',
	CanApprovePRLVL1 = 'prlvl1',
	CanApprovePRLVL2 = 'prlvl2',
	CanApprovePRLVL3 = 'prlvl3',
	CanApprovePRLVL4 = 'prlvl4',
	CanApprovePRLVL5 = 'prlvl5',
	CanApprovePOLVL1 = 'polvl1',
	CanApprovePOLVL2 = 'polvl2',
	CanApprovePOLVL3 = 'polvl3',
	CanApprovePOLVL4 = 'polvl4',
	CanApprovePOLVL5 = 'polvl5',
	CanApprovePaymentLVL1 = 'pylvl1',
	CanApprovePaymentLVL2 = 'pylvl2',
	CanApprovePaymentLVL3 = 'pylvl3',
	CanApprovePaymentLVL4 = 'pylvl4',
	CanApprovePaymentLVL5 = 'pylvl5',
	CanBounceCheques = 'cbc',
	CanViewCollections = 'can-view-collections',
	CanViewChartOfAccounts='can-view-account-chart',
	CanManageCustomAccounts='can-manage-custom-accounts',
	CanEditPMSetting='can-edit-pm-setting',
	CanSeeSOAForContactAdhoc='can-see-soa-for-contact-adhoc',
	CanCreateAdhoc='can-create-adhoc',
	CanRefundLeasingPayment='can-refund-leasing-payment',
	CanGenerateAccountReceivableOutstandingReport= 'can-generate-account-receivable',
	CanGenerateSecurityDepositReport= 'can-generate-security-deposit',
	CanGenerateFutureInvoicesReport= 'can-generate-future-invoices',
	CanGenerateAgedCreditorsReport= 'can-generate-aged-creditors-invoices',
}
export const ContactFunctionsDescription: Record<ContactFunctionsCodeEnum, string> = {
	cm: 'Community Manager',
	co: 'Communications Officer',
	pc: 'Property Coordinator',
	pm: 'Property Manager',
	dc: 'Document Control',
	household: 'Household',
	invoiced: 'Invoice Target',
	landlord: 'Landlord',
	cam: 'Community Accounting Manager',
	pa: 'Project Accountant',
	tenant: 'Tenant',
	agentPrimary: 'Primary Agent',
	agent: 'Agent',
	guest: 'Guest',
	prc: 'PR Creator',
	poc: 'PO Creator',
	pra: 'PR Approver',
	poa: 'PO Approver',
	ic: 'Purchase Invoices Creator',
	ip: 'Purchase Invoices Poster',
	ir: 'Invoices Reverser',
	sacr: 'Sales Creator',
	saqa: 'Quotes Approver',
	saic: 'Sales Invoices Creator',
	saii: 'Sales Invoices Issuer',
	sacnc: 'Sales Credit Notes Creator',
	sacni: 'Sales Credit Notes Issuer',
	sarc: 'Sales Receipts Creator',
	sari: 'Sales Receipts Issuer',
	salc: 'Leads Creator',
	ufcpi: 'Unit Financials Create Bulk Invoices',
	ufcpr: 'Unit Financials Create Bulk Receipts',
	ufmi: 'Unit Financials Mollak Invoices',
	ufmr: 'Unit Financials Mollak Receipts',
	ufscps: 'Unit Financials View SC Position Statement',
	ufcra: 'Unit Financials Create Receipt/Acknowledgement ',
	ufpa: 'Unit Financials Post Allowance',
	uftb: 'Unit Financials Transfer Balance',
	ufat: 'Unit Financials Audit Trail',
	ufscs: 'Unit Financials View SC Statement',
	glcmj: 'General Ledger Create Manual Journal',
	glvh: 'General Ledger View History',
	ccmtc: 'Cash And Cheque Movements Transfer Cash',
	ccmr: 'Cash And Cheque Movements Reconcile',
	ccmuf: 'Cash And Cheque Movements Upload File',
	ada: 'Adjustments Adjust',
	advd: 'Adjustments View Details',
	reafsp: 'Reports Annual Financial Statement Book',
	retb: 'Reports Trial Balance',
	repl: 'Reports Profit And Loss',
	revl: 'Reports VAT Liability',
	rejr: 'Reports Journal Report',
	regfrf: 'Reports GF/RF Report',
	regle: 'Reports General Ledger Export',
	reacr: 'Reports Aged Creditors Report',
	reps: 'Reports Position Statement',
	fsgs: 'Financial Settings General Settings',
	fscaa: 'Financial Settings Community Accounts Add',
	fscae: 'Financial Settings Community Accounts Edit',
	fscad: 'Financial Settings Community Accounts Delete',
	cd: 'Edit Contact Details',
	cdc: 'Client Document Control',
	recf: 'Reports Cash Flow Statement',
	pgpe: 'Property Group Profile Edit',
	pgc: 'Property Group Create',
	rebs: 'Reports Balance Sheet',
	csbae: 'Budget setting as Executing',
	crb: 'Budget Creation',
	proc: 'Procurer',
	revp: 'Purchase Reversing',
	voip: 'Purchase Voiding',
	voipi: 'Purchase Invoice Voiding',
	voipp: 'Purchase Payment Voiding',
	vopucn: 'Void Purcahse Credit Note',
	dashboardedit: 'Create And Edit Reports and Dashboards',
	vosa: 'Void Sale',
	vosain: 'Void Sale Invoice',
	vosarc: 'Void Sale Receipt',
	vosacn: 'Void Sale Credit Note',
	voulrc: 'Void Utility Ledger Receipt',
	voulin: 'Void Utility Ledger Invoice',
	voulcn: 'Void Utility Ledger Credit Note',
	voulscdp: 'Void Utility Ledger Security Deposit',
	voscin: 'Void Service Charge Invoice',
	voscrc: 'Void Service Charge Receipt',
	vosccn: 'Void Service Charge Credit Note',
	volain: 'Can void leasing ledger invoice',
	volapa: 'Can void leasing ledger payment',
	volascdp: 'Void Leasing Ledger Security Deposit',
	rer: 'Requests E-Mails Recieve',
	cda: 'Collections Dashboard Access',
	's-con': 'Site Contact',
	lh: 'Legal Handler',
	ma: 'Migration Access',
	alv: 'Audit Log Viewer',
	pgse: 'Property Group Settings Edit',
	requestro: 'Requests Read only',
	vca: 'Vendors Creator And Adder',
	pgdeveloper: 'property Group Developer',
	avr: 'Approve Voiding Request',
	rvr: 'Reject Voiding Request',
	'pu-unapprove': 'Purchase Un-Approve',
	eps: 'Edit Procurement Settings',
	voscrrc: 'Void Service Charge Receipt Reversal',
	ps: 'Edit Request Payment Status',
	epc: 'External Party Contact',
	svcr: 'Show VAT Consultant Report - Only external Consultants',
	mjwpa: 'Perform Actions On Major Work Permits',
	lnrl: 'Legal Notice Reminder Log',
	uffsc: 'Unit Financials Flush SC',
	rcvml: 'Receive Vendor Emails',
	alfs: 'Allow Flash Sales',
	alfp: 'Allow Flash Purchases',
	edfs: 'Edit Flash Settings',
	voscpe: 'Void Service Charge Penalty Reversal',
	cct: 'Client contract terms',
	mrgps: 'Merge Purchases',
	mrgpis: 'Merge Purchase Invoices',
	revpicn: 'Reverse Purchase Invoice Credit Note',
	aele: 'Allow Edit PM Settings',
	rcdexn: 'Receive Documents Expiry Notifications',
	glfgl: 'General Ledger Flush GL',
	addoc: 'Allow Delete Documents',
	addocg: 'Allow Delete Documents Globally',
	cvanng: 'Access Announcements',
	cvvg: 'Access Visitors',
	rernro: 'Rental Roll Report',
	cvlst: 'Access Lost And Founds',
	fmr: 'Facility Management Report',
	ramr: 'Rental Amounts Received Report',
	esr: 'Executive Summary Report',
	acel: 'Access Contact Edit Logs',
	rsdnt: 'Resident',
	reocr: 'Occupancy Report',
	colma: 'Collection Manager',
	coloff: 'Collector Officer',
	cte: 'Transfer Equity',
	readr: 'Reports Aged Debtors Report',
	cvuirr: 'Reports Units Invoices And Receipts',
	expdis: 'Reports Expense Distribution Statement',
	rvtin: 'Reports VAT Input/Output',
	reindis: 'Reports Income Distribution',
	rerecon: 'Reports Reconciliation ',
	rebds: 'Reports Budgets vs Actuals',
	mrgsa: 'Merge Sales',
	mrgsain: 'Merge Sales Invoices',
	aadl: 'Allow Activating/Deactivating Leases',
	avbup: 'Allow Vendors Bulk Upload',
	eteb: 'Enable Tenants Export Button',
	eleb: 'Enable Landlords Export Button',
	repas: 'Reports Payable Accounts Statement',
	csed: 'Allow Dashboard Access ',
	fm: 'Finance Manager',
	apm: 'Asset Portfolio Manager',
	ppc: 'Purchase Payment Create',
	repvr: 'Reports Vendor Ledger',
	cvp: 'Can view the proof of payment requests',
	cep: 'Can edit the proof of payment requests',
	vial: 'Purchase Invoice Audit Log',
	oa: 'Owners Committee Member',
	law: 'Lawyer',
	rstusr: 'Restricted User',
	'role-support': 'Support user role',
	prlvl1: 'Can approve PR (Level 1)',
	prlvl2: 'Can approve PR (Level 2)',
	prlvl3: 'Can approve PR (Level 3)',
	prlvl4: 'Can approve PR (Level 4)',
	prlvl5: 'Can approve PR (Level 5)',
	polvl1: 'Can approve PO (Level 1)',
	polvl2: 'Can approve PO (Level 2)',
	polvl3: 'Can approve PO (Level 3)',
	polvl4: 'Can approve PO (Level 4)',
	polvl5: 'Can approve PO (Level 5)',
	pylvl1: 'Can approve purchase payment (Level 1)',
	pylvl2: 'Can approve purchase payment (Level 2)',
	pylvl3: 'Can approve purchase payment (Level 3)',
	pylvl4: 'Can approve purchase payment (Level 4)',
	pylvl5: 'Can approve purchase payment (Level 5)',
	cbc: 'Can bounce a cheque',
	"can-view-collections": 'Can View Collections',
	'can-view-account-chart': 'Can View Chart Of Accounts',
	'can-manage-custom-accounts': 'Can manage custom account',
	"can-edit-pm-setting": 'Can edit PM setting',
	"can-see-soa-for-contact-adhoc": 'Can see SOA for contact (ad-hoc CN/DN)',
	"can-create-adhoc": 'Can create ad-hoc CN / DN',
	"can-refund-leasing-payment": 'Can refund leasing payment',
	"can-generate-account-receivable": 'Can generate account receivable outstanding report',
	"can-generate-security-deposit": 'Can generate security deposit report',
	"can-generate-future-invoices": 'Can generate future invoices report',
	[ContactFunctionsCodeEnum.CanGenerateAgedCreditorsReport]: 'Can generate aged creditors report',
}
