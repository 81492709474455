import { DocFile } from './../models/document.pg.model';

export function sanitizeFileName(fileName: string): string {
  // Define a regex pattern for invalid characters
  const invalidCharsRegex = /[\\/:*?"<>|%]/g;
  return fileName.replace(invalidCharsRegex, '');
};



export function sanitizeFileNames (files: DocFile[]): DocFile[] {
  return files.map((file) => {
    // Create a new File object with the sanitized name
    if(!!file?.document?.name){
      const sanitizedFileName = sanitizeFileName(file.document.name);
      return {...file, document:{...(file.document), name:sanitizedFileName} } as DocFile;
    }
    return file;
  });
};
