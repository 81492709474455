import { Building } from '../models/buildings.model';
import { objectify } from '../utils/objectify';
import { Client, ClientVM } from './client.model';
import { Project } from './project.models';
import { UnitInContactCommunitiesReturnType } from './unit.models';

export interface CommunitySettings {
	enableMinorWork: boolean;
	enableMajorWork: boolean;
	requireWorkPermitPreApproval: boolean;
	moveInRequiresElevator: boolean;
	moveOutRequiresElevator: boolean;
	moveInRequiresChillerReg: boolean;
	moveOutRequiresChillerNOC: boolean;
	requireDLDNumber: boolean;
	parkingManagement: 'systemManaged' | 'asNumber';
	fromAddress: string;
	fetchFromMP: boolean;
	enableMasterAccessCards: boolean;
	requireNationality: boolean;
	requireVehicleDetails: boolean;
	requirePetDetails: boolean;
	requireDetailedPetDetails: boolean;
	requireNumberOfResidents: boolean;
	requiredMoveInDocuments: RequiredDocument[];
	requiredMoveOutDocuments: RequiredDocument[];
	requiredMinorWPDocuments: RequiredDocument[];
	requiredMajorWPDocuments: RequiredDocument[];
	requiredMaintenanceWPDocuments: RequiredDocument[];
	requiredIndividualLeaseDocuments: RequiredDocument[];
	requiredCompanyLeaseDocuments: RequiredDocument[];
	enableMaintenanceLocation: boolean;
	enablleUnitViewing: boolean;
	showingBalance: boolean;
	hasPoolMaintenance: boolean;
	hasLandscape: boolean;
	requireUnitForBooking: boolean;
	requireVisitorsDetailsForBooking: boolean;
	requireConfirmationForBooking: boolean;
	confirmationDays: number;
	bookingsrequireApproval: boolean;
	enteringVisitor: boolean;
	enableWorkpermitInCommonArea: boolean;
	allowOnlyResidents: boolean;
	ownerNumberMovIn: boolean;
	needTitleDeedForTenant: boolean;
	preventAccessCardRequests: boolean;
	preventBarrierRemoteRequests: boolean;
	allowFlashSales: boolean;
	requestsRequireResidentsDetails: boolean;
	requestsRequireStaffDetails: boolean;
	requestsRequireMovingCompanyDetails: boolean;
	requestsRequireMovingCompanyDetailsForMoveOutTenant?: boolean;
	requestsRequireMovingCompanyDetailsForMoveOutLandlord?: boolean;
	dontShowSCDataForCustomers?: boolean;
	noPredefinedDocuments?: boolean;
	allowFlashPurchases?: boolean;
	offerToLeaseRequiresApproval?: boolean;
	offerToLeaseRequiresTenantApproval?: boolean;
	requireAllLandlordsApproval?: boolean;
	autoCheckoutVisitor?: boolean;
	autoCheckout?: number;
	doNotShowCommunityRule?: boolean;
	enableLeaseExpiryTenantNotifications?: boolean;
	leaseExpiryReminder?: number;
	enableLeaseExpiryReminder?: boolean;
	needWorkDuration?: boolean;
	workDuration?: WorkDuration[];
	enableLostFound?: boolean;
	moveInRequireEjari?: boolean;
	disableRequestRating?: boolean;
	poApprovalLevels?: POApprovalLevel[];
	enablePaymentProof?: boolean;
	enablePaymentDraft?: boolean;
	disableInvoiceApproval?: boolean;
	requirePaymentApprovalProcess?: boolean;
	pm?: PropertyGroupPMSettings
}

export interface PropertyGroupPMSettings {
	notRequireRentAndFeesBeforeLeaseSigning: boolean
	disableLeasePostingInAccounting: boolean,
	disableAccrualAccounting: boolean,
	enableStraightLineAccounting: boolean,
}

export const getDefaultPMSettings = (): PropertyGroupPMSettings => ({
    disableLeasePostingInAccounting: false,
    notRequireRentAndFeesBeforeLeaseSigning: false,
    disableAccrualAccounting: false,
    enableStraightLineAccounting: false,
});

export interface POApprovalLevel {
	contacts?: { id: string, hasRange: boolean, from?: number, to?: number }[];
	requiredApprovals: number;
	index: number;
}

export type RequiredDocument = {
	id?: string;
	description?: string;
	type: string;
	label: string;
	onlyForTenants?: boolean;
	onlyShortTerm?: boolean;
	required?: boolean;
	onlyForLandlords?: boolean;
};

const defaultCommunitySettings: CommunitySettings = {
	enableMinorWork: true,
	enableMajorWork: true,
	requireWorkPermitPreApproval: false,
	moveInRequiresElevator: false,
	moveOutRequiresElevator: false,
	moveInRequiresChillerReg: false,
	moveOutRequiresChillerNOC: false,
	requireDLDNumber: false,
	parkingManagement: 'asNumber',
	fromAddress: '',
	fetchFromMP: false,
	enableMasterAccessCards: false,
	requireNationality: false,
	requireVehicleDetails: false,
	requirePetDetails: false,
	requireDetailedPetDetails: false,
	requireNumberOfResidents: false,
	requiredMoveOutDocuments: [],
	requiredMoveInDocuments: [],
	requiredMinorWPDocuments: [],
	requiredMajorWPDocuments: [],
	requiredMaintenanceWPDocuments: [],
	requiredIndividualLeaseDocuments: [],
	requiredCompanyLeaseDocuments: [],
	enableMaintenanceLocation: false,
	enablleUnitViewing: false,
	showingBalance: false,
	hasPoolMaintenance: false,
	hasLandscape: false,
	requireUnitForBooking: false,
	requireVisitorsDetailsForBooking: false,
	requireConfirmationForBooking: false,
	confirmationDays: 2,
	bookingsrequireApproval: false,
	enteringVisitor: false,
	enableWorkpermitInCommonArea: false,
	allowOnlyResidents: false,
	ownerNumberMovIn: false,
	needTitleDeedForTenant: false,
	preventAccessCardRequests: false,
	preventBarrierRemoteRequests: false,
	allowFlashSales: false,
	requestsRequireResidentsDetails: false,
	requestsRequireStaffDetails: false,
	requestsRequireMovingCompanyDetails: false,
	requestsRequireMovingCompanyDetailsForMoveOutTenant: false,
	requestsRequireMovingCompanyDetailsForMoveOutLandlord: false,
	dontShowSCDataForCustomers: false,
	noPredefinedDocuments: false,
	allowFlashPurchases: false,
	autoCheckoutVisitor: false,
	autoCheckout: 24,
	doNotShowCommunityRule: false,
	enableLeaseExpiryTenantNotifications: false,
	offerToLeaseRequiresApproval: false,
	requireAllLandlordsApproval: false,
	leaseExpiryReminder: 90,
	enableLeaseExpiryReminder: false,
	moveInRequireEjari: false,
	enablePaymentDraft: false,
	disableInvoiceApproval:false,
	requirePaymentApprovalProcess: false,
	pm: getDefaultPMSettings()
};

export const getDefaultCommunitySettings = (): CommunitySettings => {
	return objectify( defaultCommunitySettings );
};

export interface PaymentServiceDefinitions {}

export interface AmazonPaymentServiceDefinition extends PaymentServiceDefinitions {
	merchantIdentifier: string,
	accessCode: string,
	shaType: string,
	shaRequestPhrase: string,
	shaResponsePhrase: string,
}

const defaultAmazonPaymentServiceDefinition: AmazonPaymentServiceDefinition = {
	merchantIdentifier: '',
	accessCode: '',
	shaType: '',
	shaRequestPhrase: '',
	shaResponsePhrase: '',
};

export const getDefaultAmazonPaymentServiceDefinition = (): AmazonPaymentServiceDefinition => {
	return objectify( defaultAmazonPaymentServiceDefinition );
};
export interface PaymentProcessing {
	type: string,
	definition?: PaymentServiceDefinitions,
}

const defaultPaymentProcessing: PaymentProcessing = {
	type: '',
};

export const getDefaultPaymentProcessing = (): PaymentProcessing => {
	return objectify( defaultPaymentProcessing );
};

export interface CommunityFirebase {
	id: string,
	name: string,
	isOA: boolean,
	isPM: boolean,
	isUM: boolean,
	address: any,
	unit_count: number,
	floor_count: number,
	entitlement_type: string,
	aggregate_entitlement: number,
	certification_of_occupation_on: number,
	plan_registered_on: number,
	plan_last_updated_on: number,
	plot_type: string,
	plot_number: string,
	present_use: string,
	marketing_description: string,
	contacts: any,
	notes: any,
	documents: any,
	oacontacts: any,
	oadocuments: any,
	oanotes: any,
	governance_structure: string,
	bank_accounts: any,
	cost_centers: any,
	buildings: any,
	units: any,
	vat_starting_date: any,
	vat_opening_balance: number,
	vat_opening_date: any,
	arabicName: string,
	ccDefault: string,
	trn: string,
	grace: number,
	overdue_penalty: number,
	fy_start: string,
	first_general_assembly_on: string,
	last_general_assembly_on: string,
	last_board_on: string,
	status: string,
	common_area: number,
	cooling_system: string,
	signature: string,
	abbreviation?: string,
	settings?: CommunitySettings,
	isActive: boolean,
	mollakid: string,
	mollakName: string,
	mollakReceiptAutoSyncStart: string,
	mollakIsMasterCommunity: boolean,
}

export type Community = Omit<CommunityTuple, 'migrationId'>;

export interface CommunityTuple {
	id: string,
	name: string,
	isOA: boolean,
	isPM: boolean,
	isUM: boolean,
	address: string,
	unitCount: number,
	floorCount: number,
	entitlementType: string,
	aggregateEntitlement: number,
	certificationOfOccupationOn: string,
	planRegisteredOn: string,
	planLastUpdatedOn: string,
	plotType: string,
	plotNumber: string,
	presentUse: string,
	marketingDescription: string,
	governanceStructure: string,
	vatStartingDate: string,
	vatOpeningBalance: number,
	vatOpeningDate: string,
	arabicName: string,
	ccDefault: string,
	trn: string,
	grace: number,
	overduePenalty: number,
	fyStart: string,
	firstGeneralAssemblyOn: string,
	lastGeneralAssemblyOn: string,
	lastBoardOn: string,
	status: string,
	commonArea: number,
	coolingSystem: string,
	signature?: string,
	abbreviation: string,
	client?: string,
	settings?: CommunitySettings,
	rules: string,
	captureEmail: string,
	subCommunityId: string,
	isActive: boolean,
	paymentProcessing?: PaymentProcessing,
	collectionAssignedTo?: string,
	floorOrder?: boolean,
	oldId?: string | null,
	migratedFrom?: string | null,
	mollakId: string,
	mollakName: string,
	mollakReceiptAutoSyncStart: string,
	mollakIsMasterCommunity: boolean,
}

export const getDefaultCommunity = () => {
	return objectify( defaultCommunity );
};

export interface CommunityTupleVM extends CommunityTuple {
	units: any,
	clientInfo?: ClientVM,
	subCommunityName: string,
}
const defaultCommunity: Required<CommunityTuple> = {
	id: '',
	name: '',
	isOA: false,
	isPM: false,
	isUM: false,
	address: '',
	unitCount: 0,
	floorCount: 0,
	entitlementType: '',
	aggregateEntitlement: 0,
	certificationOfOccupationOn: '0',
	planRegisteredOn: '0',
	planLastUpdatedOn: '0',
	plotType: '',
	plotNumber: '',
	presentUse: '',
	marketingDescription: '',
	governanceStructure: '',
	vatStartingDate: '',
	vatOpeningBalance: 0,
	vatOpeningDate: '',
	arabicName: '',
	ccDefault: '',
	trn: '',
	grace: 0,
	overduePenalty: 0,
	fyStart: '',
	firstGeneralAssemblyOn: '',
	lastGeneralAssemblyOn: '',
	lastBoardOn: '',
	status: '',
	commonArea: 0,
	coolingSystem: '',
	signature: '',
	abbreviation: 'NONE',
	client: '',
	settings: getDefaultCommunitySettings(),
	rules: '',
	captureEmail: '',
	subCommunityId: '',
	isActive: false,
	paymentProcessing: getDefaultPaymentProcessing(),
	collectionAssignedTo: '',
	floorOrder: false,
	oldId: null,
	migratedFrom: null,
	mollakId: '',
	mollakName: '',
	mollakReceiptAutoSyncStart: '',
	mollakIsMasterCommunity: false,
};

const removeEmptyFields = ( data: any ) => {
	Object.keys( data ).forEach( k => {
		if ( ( data[ k ] === undefined ) || ( data[ k ] === 'undefined' ) || ( data[ k ]?.length <= 0 ) ) delete data[ k ];
	} );
	return data;
};

export const transformCommunity = (
	fromFBCommunity: Partial<CommunityFirebase> ) => {
	const community = {
		id: fromFBCommunity.id || '',
		name: fromFBCommunity.name || '',
		isOA: fromFBCommunity.isOA || false,
		isPM: fromFBCommunity.isPM || false,
		isUM: fromFBCommunity.isUM || false,
		address: fromFBCommunity.address || '',
		unitCount: fromFBCommunity.unit_count || 0,
		floorCount: fromFBCommunity.floor_count || 0,
		entitlementType: fromFBCommunity.entitlement_type || '',
		aggregateEntitlement: fromFBCommunity.aggregate_entitlement || '',
		certificationOfOccupationOn: ( fromFBCommunity.certification_of_occupation_on || 0 ).toString(),
		planRegisteredOn: ( fromFBCommunity.plan_registered_on || 0 ).toString(),
		planLastUpdatedOn: ( fromFBCommunity.plan_last_updated_on || 0 ).toString(),
		plotType: fromFBCommunity.plot_type || '',
		plotNumber: fromFBCommunity.plot_number || '',
		presentUse: fromFBCommunity.present_use || '',
		marketingDescription: fromFBCommunity.marketing_description || '',
		governanceStructure: fromFBCommunity.governance_structure || '',
		vatStartingDate: fromFBCommunity.vat_starting_date || '',
		vatOpeningBalance: fromFBCommunity.vat_opening_balance || '',
		vatOpeningDate: fromFBCommunity.vat_opening_date || '',
		arabicName: fromFBCommunity.arabicName || '',
		ccDefault: fromFBCommunity.ccDefault || '',
		trn: fromFBCommunity.trn || '',
		grace: fromFBCommunity.grace || '',
		overduePenalty: fromFBCommunity.overdue_penalty || '',
		fyStart: fromFBCommunity.fy_start || '',
		firstGeneralAssemblyOn: fromFBCommunity.first_general_assembly_on || '',
		lastGeneralAssemblyOn: fromFBCommunity.last_general_assembly_on || '',
		lastBoardOn: fromFBCommunity.last_board_on || '',
		status: fromFBCommunity.status || '',
		commonArea: fromFBCommunity.common_area || '',
		coolingSystem: fromFBCommunity.cooling_system || '',
		signature: fromFBCommunity?.signature || '',
		abbreviation: fromFBCommunity.abbreviation || 'NONE',
		isActive: fromFBCommunity.isActive || false,
		mollakId: fromFBCommunity.mollakid || '',
		mollakName: fromFBCommunity.mollakName || '',
		mollakReceiptAutoSyncStart: fromFBCommunity.mollakReceiptAutoSyncStart || '',
		mollakIsMasterCommunity: fromFBCommunity.mollakIsMasterCommunity || false,

		/*
				geography: fromFBCommunity.Geography,
				oldId: fromFBCommunity.oldId,
				migratedFrom: fromFBCommunity.migratedFrom,
			*/

	} as CommunityTuple;
	return removeEmptyFields( community );
};

export const reverseTransformCommunity = ( fromPGCommunity: Partial<CommunityTuple> ) => {
	const community = {
		id: fromPGCommunity.id || '',
		name: fromPGCommunity.name || '',
		isOA: fromPGCommunity.isOA || false,
		isPM: fromPGCommunity.isPM || false,
		isUM: fromPGCommunity.isUM || false,
		address: fromPGCommunity.address || '',
		unit_count: fromPGCommunity.unitCount || 0,
		floor_count: fromPGCommunity.floorCount || 0,
		entitlement_type: fromPGCommunity.entitlementType || '',
		aggregate_entitlement: fromPGCommunity.aggregateEntitlement || '',
		certification_of_occupation_on: parseInt( fromPGCommunity.certificationOfOccupationOn || '0', 10 ),
		plan_registered_on: parseInt( fromPGCommunity.planRegisteredOn || '0', 10 ),
		plan_last_updated_on: parseInt( fromPGCommunity.planLastUpdatedOn || '0', 10 ),
		plot_type: fromPGCommunity.plotType || '',
		plot_number: fromPGCommunity.plotNumber || '',
		present_use: fromPGCommunity.presentUse || '',
		marketing_description: fromPGCommunity.marketingDescription || '',
		governance_structure: fromPGCommunity.governanceStructure || '',
		vat_starting_date: fromPGCommunity.vatStartingDate || '',
		vat_opening_balance: fromPGCommunity.vatOpeningBalance || '',
		vat_opening_date: fromPGCommunity.vatOpeningDate || '',
		arabicName: fromPGCommunity.arabicName || '',
		ccDefault: fromPGCommunity.ccDefault || '',
		trn: fromPGCommunity.trn || '',
		grace: fromPGCommunity.grace || '',
		overdue_penalty: fromPGCommunity.overduePenalty || '',
		fy_start: fromPGCommunity.fyStart || '',
		first_general_assembly_on: fromPGCommunity.firstGeneralAssemblyOn || '',
		last_general_assembly_on: fromPGCommunity.lastGeneralAssemblyOn || '',
		last_board_on: fromPGCommunity.lastBoardOn || '',
		status: fromPGCommunity.status || '',
		common_area: fromPGCommunity.commonArea || '',
		cooling_system: fromPGCommunity.coolingSystem || '',
		signature: fromPGCommunity?.signature || '',
		abbreviation: fromPGCommunity.abbreviation || 'NONE',
		isActive: fromPGCommunity.isActive || false,
		mollakid: fromPGCommunity.mollakId || '',
		mollakName: fromPGCommunity.mollakName || '',
		mollakReceiptAutoSyncStart: fromPGCommunity.mollakReceiptAutoSyncStart || '',
		mollakIsMasterCommunity: fromPGCommunity.mollakIsMasterCommunity || false,
		
	} as CommunityFirebase;
	return removeEmptyFields( community );
};

export const transformProjectToCommunity = ( fromFBCommunity: Partial<Project> ) => {
	let community = {
		id: fromFBCommunity.id || '',
		name: fromFBCommunity.name || '',
		isOA: fromFBCommunity.isOA || false,
		isPM: fromFBCommunity.isPM || false,
		isUM: fromFBCommunity.isUM || false,
		address: fromFBCommunity.address || '',
		unitCount: fromFBCommunity.unit_count || 0,
		floorCount: fromFBCommunity.floor_count || 0,
		entitlementType: fromFBCommunity.entitlement_type || '',
		aggregateEntitlement: fromFBCommunity.aggregate_entitlement || '',
		certificationOfOccupationOn: ( fromFBCommunity.certification_of_occupation_on || 0 ).toString(),
		planRegisteredOn: ( fromFBCommunity.plan_registered_on || 0 ).toString(),
		planLastUpdatedOn: ( fromFBCommunity.plan_last_updated_on || 0 ).toString(),
		plotType: fromFBCommunity.plot_type || '',
		plotNumber: fromFBCommunity.plot_number || '',
		presentUse: fromFBCommunity.present_use || '',
		marketingDescription: fromFBCommunity.marketing_description || '',
		governanceStructure: fromFBCommunity.governance_structure || '',
		vatStartingDate: fromFBCommunity.vat_starting_date || '',
		vatOpeningBalance: fromFBCommunity.vat_opening_balance || '',
		vatOpeningDate: fromFBCommunity.vat_opening_date || '',
		arabicName: fromFBCommunity.arabicName || '',
		ccDefault: fromFBCommunity.ccDefault || '',
		trn: fromFBCommunity.trn || '',
		grace: fromFBCommunity.grace || '',
		overduePenalty: fromFBCommunity.overdue_penalty || '',
		fyStart: fromFBCommunity.fy_start || '',
		firstGeneralAssemblyOn: fromFBCommunity.first_general_assembly_on || '',
		lastGeneralAssemblyOn: fromFBCommunity.last_general_assembly_on || '',
		lastBoardOn: fromFBCommunity.last_board_on || '',
		status: fromFBCommunity.status || '',
		commonArea: fromFBCommunity.common_area || '',
		coolingSystem: fromFBCommunity.cooling_system || '',
		signature: fromFBCommunity?.signature || '',
		abbreviation: fromFBCommunity.abbreviation || 'NONE',
		client: fromFBCommunity.clientInfo?.id || '',
		settings: fromFBCommunity.settings,
		rules: fromFBCommunity.rules,
		captureEmail: fromFBCommunity.captureEmail || '',
		subCommunityId: fromFBCommunity.subCommunityId || '',
		isActive: fromFBCommunity.isActive || false,
		paymentProcessing: fromFBCommunity.paymentProcessing,
		collectionAssignedTo: fromFBCommunity.collectionAssignedTo,
		floorOrder: fromFBCommunity.floorOrder || false,
		mollakId: fromFBCommunity.mollakid || '',
		mollakName: fromFBCommunity.mollakName || '',
		mollakReceiptAutoSyncStart: fromFBCommunity.mollakReceiptAutoSyncStart || '',
		mollakIsMasterCommunity: fromFBCommunity.mollakIsMasterCommunity || false,
		
	} as CommunityTuple;
	community = removeEmptyFields( community );
	if ( !community.client ) community.client = '';
	return community;
};

export const reverseTransformCommunityToProject = ( fromPGCommunity: Partial<CommunityTupleVM & {propertyGroupId?:string}> ) => {
	const community = {
		id: fromPGCommunity.id || fromPGCommunity.propertyGroupId || '',
		name: fromPGCommunity.name || '',
		isOA: fromPGCommunity.isOA || false,
		isPM: fromPGCommunity.isPM || false,
		isUM: fromPGCommunity.isUM || false,
		address: fromPGCommunity.address || '',
		unit_count: fromPGCommunity.unitCount || 0,
		floor_count: fromPGCommunity.floorCount || 0,
		entitlement_type: fromPGCommunity.entitlementType || '',
		aggregate_entitlement: fromPGCommunity.aggregateEntitlement || '',
		certification_of_occupation_on: parseInt( fromPGCommunity.certificationOfOccupationOn || '0', 10 ),
		plan_registered_on: parseInt( fromPGCommunity.planRegisteredOn || '0', 10 ),
		plan_last_updated_on: parseInt( fromPGCommunity.planLastUpdatedOn || '0', 10 ),
		plot_type: fromPGCommunity.plotType || '',
		plot_number: fromPGCommunity.plotNumber || '',
		present_use: fromPGCommunity.presentUse || '',
		marketing_description: fromPGCommunity.marketingDescription || '',
		governance_structure: fromPGCommunity.governanceStructure || '',
		vat_starting_date: fromPGCommunity.vatStartingDate || '',
		vat_opening_balance: fromPGCommunity.vatOpeningBalance || '',
		vat_opening_date: fromPGCommunity.vatOpeningDate || '',
		arabicName: fromPGCommunity.arabicName || '',
		ccDefault: fromPGCommunity.ccDefault || '',
		trn: fromPGCommunity.trn || '',
		grace: fromPGCommunity.grace || '',
		overdue_penalty: fromPGCommunity.overduePenalty || '',
		fy_start: fromPGCommunity.fyStart || '',
		first_general_assembly_on: fromPGCommunity.firstGeneralAssemblyOn || '',
		last_general_assembly_on: fromPGCommunity.lastGeneralAssemblyOn || '',
		last_board_on: fromPGCommunity.lastBoardOn || '',
		status: fromPGCommunity.status || '',
		common_area: fromPGCommunity.commonArea || '',
		cooling_system: fromPGCommunity.coolingSystem || '',
		signature: fromPGCommunity?.signature || '',
		abbreviation: fromPGCommunity.abbreviation || 'NONE',
		clientInfo: fromPGCommunity.clientInfo,
		settings: fromPGCommunity.settings,
		rules: fromPGCommunity.rules,
		captureEmail: fromPGCommunity.captureEmail,
		subCommunityId: fromPGCommunity.subCommunityId || '',
		subCommunityName: !!fromPGCommunity.subCommunityId ? fromPGCommunity.subCommunityName : '',
		isActive: fromPGCommunity.isActive || false,
		paymentProcessing: fromPGCommunity.paymentProcessing,
		collectionAssignedTo: fromPGCommunity.collectionAssignedTo,
		floorOrder: fromPGCommunity.floorOrder || false,
		mollakid: fromPGCommunity.mollakId || '',
		mollakName: fromPGCommunity.mollakName || '',
		mollakReceiptAutoSyncStart: fromPGCommunity.mollakReceiptAutoSyncStart || '',
		mollakIsMasterCommunity: fromPGCommunity.mollakIsMasterCommunity || false,
		
	} as Project;
	return removeEmptyFields( community );
};

export interface WorkDuration {
	duration: number,
	durationType: 'Hours' | 'Days' | 'Weeks' | 'Months',
	workCatType: 'Minor' | 'Major' | 'Minor & Major',
}

export interface CommunityPostRequestBody {
	/**
	 * client id, uuid
	 */
	client: string;
	name: string;
	abbreviation: string;

	/**
	 * sub community id, uuid
	 */
	subCommunityId: string;
	/**
	 * default: false
	 */
	isOA?: boolean;
	isPM?: boolean
	isActive?: boolean;
	mollakId?:number;
}


//for documentation
export interface CommunityTuplePost{
	client : string,
    abbreviation : string,
    name : string,
    subCommunityId : string,
    isOA ?: boolean,
}
export interface CommunityNotifyLandlordsPost{
	projectID : string,
}
export interface CommunityGetByContact{
	id : string,
	name : string,
	client : string,
}
export interface PropertyMultiGet{
	id : string,
	name : string,
	community : string,
	communityM : Community,
	phoneNumber : string,
	movInDisclaimer :string ,
	movOutDisclaimer :string ,
	workPermitDisclaimer :string ,
	subCommunityName :string ,
}
export interface clientPropertiesGet extends Building{
	communityM : Community,
	subCommunityName :string ,
}
export interface saveEmailCapturePost {
	communityId  : string,
	emailCapture :string ,
}

export interface CommunitiesContactAPIReturnType {
	communityName: string,
	units: UnitInContactCommunitiesReturnType[],
	communitySettings: CommunitySettings,
}
export interface PropertyGroupWithClient extends Client{
	communityId:string,
	communityName:string,
}
