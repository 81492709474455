
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CustomToastrService } from 'src/app/shared/services/customToastr.service';
import { ContactDocument, DocFile, DocumentListerInputs, DocumentPG, DocumentVM } from '../../../models/document.pg.model';
import { GeneralSearchModel } from '../../../models/generalSearch.model';
import { SystemService } from './system.service';
import { uuid } from '../../../utils/uuid';
import { sanitizeFileNames } from '../../../utils/sanitizeFileNames';
import { removeNull } from '../../../utils/cleanObject';
import { environment } from '../environments/environment';
import { CustomMessageService } from './shared/services/customPriemngMessage.service';

const BASE_API_URL = environment.apiURL;

@Injectable( {
	providedIn: 'root'
} )
export class DocumentService {
	private DOCUMENT_API = `${ BASE_API_URL }/document`;

	public MAX_DOCUMENT_SIZE = 1024 * 1024 * 12;
	public MAX_DOCUMENT_SIZE_2X = 1024 * 1024 * 64;

	constructor (
		private http: HttpClient,
		private ss: SystemService,
		private toastrService: CustomToastrService,
		private messageService: CustomMessageService ) {
	}

	public getBy = async ( key?: string, value?: string ): Promise<DocumentPG[]> => {
		try {
			const url = `${ this.DOCUMENT_API }${ ( !!key && !!value ) ? '/' + key + '/' + value : '' }`;
			const result = await firstValueFrom( this.http.get<DocumentPG[]>( url ) );
			return result;
		} catch ( error ) {
			this.toastrService.error( 'Failed to fetch Documents' );
			console.error( error );
			throw error;
		}
	}

	public getDocumentsByRef = async ( criteria: DocumentListerInputs ) => {
		const url = `${ this.DOCUMENT_API }/search`;
		const searchObject = { refType: criteria.refType, refId: criteria.refId, limitTo: criteria?.limitTo, exclude: criteria?.exclude, type: criteria?.type };
		const result = await firstValueFrom( ( this.http.post<DocumentVM[]>( url, searchObject ) ) );
		return result;
	}
	public getvendorSearchDocumentsByRef = async ( reqData ) => {
		const url = `${ this.DOCUMENT_API }/vendorSearch`;
		const result = await firstValueFrom( ( this.http.post<DocumentVM[]>( url, reqData ) ) );
		return result;
	}

	public post = async ( payload: DocumentPG /*, criteria?: DocumentListerInputs */ ): Promise<DocumentPG> => {
		try {
			const result = await firstValueFrom( this.http.post<DocumentPG>( this.DOCUMENT_API, payload ) );
			return result;
		} catch ( error ) {
			this.toastrService.error( 'Failed to add Document' );
			console.error( error );
			throw error;
		}
	}

	public postBulk = async ( payload: DocFile[]  /*, criteria?: DocumentListerInputs */, isUnique = false ): Promise<DocumentPG[]> => {
		try {
			const result = await firstValueFrom( this.http.post<DocumentPG[]>( `${ this.DOCUMENT_API }/bulk`, { payload, isUnique } ) );
			return result;
		} catch ( error ) {
			this.toastrService.error( 'Failed to add documents' );
			console.error( error );
			throw error;
		}
	}

	public put = async ( id: string | number, payload: Partial<DocumentPG>  /*, criteria?: DocumentListerInputs */ ): Promise<DocumentPG> => {
		if ( payload.id && payload.id !== id ) throw new Error( 'Id mismatch...' );
		try {
			const url = `${ this.DOCUMENT_API }/${ id.toString().trim() }`;
			payload = removeNull(payload);
			const result = await firstValueFrom( this.http.put<DocumentPG>( url, payload ) );
			return result;
		} catch ( error ) {
			this.toastrService.error( 'Failed to update Document' );
			console.error( error );
			throw error;
		}
	}

	public delete = async ( id: string | number /*, criteria?: DocumentListerInputs */ ): Promise<DocumentPG> => {
		try {
			const url = `${ this.DOCUMENT_API }/${ id.toString().trim() }`;
			const result = await firstValueFrom( this.http.delete<DocumentPG>( url ) );
			return result;
		} catch ( error ) {
			this.toastrService.error( 'Failed to fetch Document' );
			console.error( error );
			throw error;
		}
	}

	public search = async ( searchObject: GeneralSearchModel ): Promise<DocumentPG[]> => {
		try {
			const url = `${ this.DOCUMENT_API }/search`;
			const result = await firstValueFrom( this.http.post<DocumentPG[]>( url, searchObject ) );
			return result;
		} catch ( error ) {
			this.toastrService.error( 'Failed to find Document' );
			console.error( error );
			throw error;
		}
	}

	public upload = async ( files: DocFile[] ): Promise<string[]> => {
		try {
			const formData = new FormData();

			files.forEach( fileDoc => {
				formData.append( fileDoc.document.id, fileDoc.file );
			} );
			const url = `${ this.DOCUMENT_API }/upload`;
			const result = await firstValueFrom( this.http.post<string[]>( url, formData ) );
			return result;
		} catch ( error ) {
			this.toastrService.error( 'Failed to find Document' );
			console.error( error );
			throw error;
		}
	}
	public uploadFiles = async ( files: DocFile[], isUnique = false ,isLease?:boolean): Promise<DocumentPG[]> => {
		try {
			files = sanitizeFileNames(files)
			const url0 = `${ this.DOCUMENT_API }/saveFiles`;
			const toInsert = ( files || [] ).map( file => file.document );
			const docs = await firstValueFrom( this.http.post<string[]>( url0, { files: toInsert, isUnique } ) );
			const formData = new FormData();

			files.forEach( fileDoc => {
				formData.append( fileDoc.document.id, fileDoc.file,fileDoc.document.name );
			} );
			const url = `${ this.DOCUMENT_API }/uploadFiles`;
			const result = await firstValueFrom( this.http.post<DocumentPG[]>( url, formData ) );
			this.messageService.success( {   title:'Success',message: 'Document Uploaded Successfully.', sticky: true } );
			if(isLease){
				this.messageService.info({message:'You can view the Document in the Lease Documents'})
			}
			return result;
		} catch ( error ) {
			console.error( error );
			this.messageService.error( {   title:'Error',message: 'Document Upload Failed.', sticky: true } );
			throw error;
		}
	}
	public uploadOneFileAndGetURL = async ( file ): Promise<string> => {
		try {
			const formData = new FormData();
			formData.append( uuid(), file );

			const url = `${ this.DOCUMENT_API }/uploadFileAndGetURL`;
			const result = await firstValueFrom( this.http.post<string>( url, formData ) );
			this.messageService.success( {   title:'Success',message: 'Document Uploaded Successfully.', sticky: true } );
			return result;
		} catch ( error ) {
			console.error( error );
			this.messageService.error( {   title:'Error',message: 'Document Upload Failed.', sticky: true } );
			throw error;
		}
	}

	public deleteByref = async ( refId: string, refType: string, type?: string ): Promise<DocumentPG> => {
		try {
			const url = `${ this.DOCUMENT_API }/${ refId.toString().trim() }/${ refType.toString().trim() }/${ type?.toString()?.trim() }`;
			const result = await firstValueFrom( this.http.delete<DocumentPG>( url ) );
			return result;
		} catch ( error ) {
			this.toastrService.error( 'Failed to fetch Document' );
			console.error( error );
			throw error;
		}
	}
	public getContactDocuments = async ( community: string ): Promise<ContactDocument[]> => {
		try {
			const url = `${ this.DOCUMENT_API }/contactDocuments`;
			const result = await firstValueFrom( this.http.post<ContactDocument[]>( url, { community } ) );
			return result;
		} catch ( error ) {
			this.toastrService.error( 'Failed to find Document' );
			console.error( error );
			throw error;
		}
	}
	public getDocumentBlob = async(file, fileType)=>{
		try {
			const url = `${ this.DOCUMENT_API }/blob`;
			const result = await firstValueFrom(this.http.post<any>( url, {file}, { responseType: 'arraybuffer' as 'json',
				headers: { 'Content-Type': 'application/json', 'Accept': fileType }
			} ) );
			const arr = new Uint8Array(result);
      const blob = new Blob([arr], {
         type: fileType
      });
			return blob;

		} catch ( error ) {
			this.toastrService.error( 'Failed to find Document' );
			console.error( error );
			throw error;
		}
	}

	public putBulk = async ( payload: DocumentPG[] ): Promise<DocumentPG[]> => {
		try {
			await firstValueFrom( this.http.put<DocumentPG[]>( `${ this.DOCUMENT_API }/bulk`, { payload } ) );
			this.messageService.success( {   title:'Success',message: 'Documents Uploaded Successfully.', sticky: true } );
			return payload;
		} catch ( error ) {
			console.error( error );
			this.messageService.error( {   title:'Error',message: 'Failed to Update Documents.', sticky: true } );
			throw error;
		}
	}

	public addLeasLandlordDocuments = async ( refType: string, refId: string, request: string ) => {
		try {
		const url = `${ this.DOCUMENT_API }/addLeasLandlordDocuments`;
		const documentInfo = { refType, refId, request };
		await firstValueFrom( ( this.http.post<any>( url, documentInfo ) ) );
		} catch ( error ) {
			this.messageService.error( {   title:'Error',message: `Failed to update ${ refType } Documents.`, sticky: true } );
			console.error( error );
			throw error;
		}
	}
	addMessage ( severity, summary, key, detail ) {
		this.messageService.add( { severity, summary, key, detail } );
	}
}
